import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';

import { Direction, Page, SupplierDto, SupplierEntity } from '../../../dtos';

export class SupplierResource {
    private readonly _api = inject(HttpClient);

    public createSupplier(supplierDto: SupplierDto): Observable<SupplierEntity> {
        return this._api.post<SupplierEntity>(`contact/v1/suppliers`, supplierDto);
    }

    public getSupplier(supplierId: number): Observable<SupplierEntity> {
        return this._api.get<SupplierEntity>(`contact/v1/suppliers/${supplierId}`);
    }

    public getSuppliers(params?: {
        listAll?: boolean;
        page?: number;
        size?: number;
        sort?: string;
        direction?: Direction;
        searchText?: string;
    }): Observable<Page<SupplierEntity>> {
        return this._api.get<Page<SupplierEntity>>(`contact/v1/suppliers`, { params: { ...params } });
    }

    public updateSupplier(supplierId: number, supplierDto: SupplierDto): Observable<SupplierEntity> {
        return this._api.put<SupplierEntity>(`contact/v1/suppliers/${supplierId}`, supplierDto);
    }

    public toggleSupplierStatus(supplierId: number): Observable<SupplierEntity> {
        return this._api.put<SupplierEntity>(`contact/v1/suppliers/toggle-active/${supplierId}`, null);
    }

    public deleteSupplier(supplierId: number): Observable<void> {
        return this._api.delete<void>(`contact/v1/suppliers/${supplierId}`);
    }
}
