import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    standalone: true,
    name: 'capitalize',
})
export class CapitalizePipe implements PipeTransform {
    public transform(value: string): string {
        if (!value) return value;
        return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    }
}
