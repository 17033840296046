import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    standalone: true,
    name: 'hasItems',
})
export class HasItemsPipe implements PipeTransform {
    public transform(value: any): boolean {
        return value && (Array.isArray(value) ? value.length > 0 : true);
    }
}
