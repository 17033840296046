import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';

import { DisciplineDto, DisciplineEntity, Page } from '../../../dtos';

export class DisciplinesResource {
    readonly #api = inject(HttpClient);

    public getDisciplines(): Observable<Page<DisciplineEntity>> {
        return this.#api.get<Page<DisciplineEntity>>('team/v1/disciplines');
    }

    public getDiscipline(disciplineId: number): Observable<DisciplineEntity> {
        return this.#api.get<DisciplineEntity>(`team/v1/disciplines/${disciplineId}`);
    }

    public createDiscipline(discipline: DisciplineDto): Observable<DisciplineEntity> {
        return this.#api.post<DisciplineEntity>('team/v1/disciplines', discipline);
    }

    public deleteDiscipline(disciplineId: number): Observable<DisciplineEntity> {
        return this.#api.delete<DisciplineEntity>(`team/v1/disciplines/${disciplineId}`);
    }

    public updateDiscipline(discipline: DisciplineDto): Observable<DisciplineEntity> {
        return this.#api.put<DisciplineEntity>(`team/v1/disciplines/${discipline.disciplineId}`, discipline);
    }
}
