import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';

import { ClosureReasonDto, Page } from '../../../dtos';

export class ClosureReasonResource {
    private readonly _api = inject(HttpClient);

    public createClosureReason(closureReasonDto: ClosureReasonDto): Observable<ClosureReasonDto> {
        return this._api.post<ClosureReasonDto>(`casefile/v1/closure-reasons`, closureReasonDto);
    }

    public getClosureReasons(params: { page?: number; size?: number }): Observable<Page<ClosureReasonDto>> {
        return this._api.get<Page<ClosureReasonDto>>(`casefile/v1/closure-reasons`, { params: { ...params } });
    }

    public getClosureReason(closureReasonId: number): Observable<ClosureReasonDto> {
        return this._api.get<ClosureReasonDto>(`casefile/v1/closure-reasons/${closureReasonId}`);
    }

    public updateClosureReason(closureReasonId: number, closureReasonDto: ClosureReasonDto): Observable<ClosureReasonDto> {
        return this._api.put<ClosureReasonDto>(`casefile/v1/closure-reasons/${closureReasonId}`, closureReasonDto);
    }

    public deleteClosureReason(closureReasonId: number): Observable<void> {
        return this._api.delete<void>(`casefile/v1/closure-reasons/${closureReasonId}`);
    }
}
