export const LedgerAccountTypes = [
    {
        type: 'Assets',
        category: [{ value: 'Current assets' }, { value: 'Inventory' }],
    },
    {
        type: 'Liabilities',
        category: [{ value: 'Current liabilities' }],
    },
    {
        type: 'Income',
        category: [{ value: 'Operating revenue' }, { value: 'Discount' }, { value: 'Other income' }],
    },
    {
        type: 'Expense',
        category: [
            { value: 'Cost of goods sold' },
            { value: 'Cost of sales' },
            { value: 'Operating expenses' },
            { value: 'Other expenses' },
        ],
    },
];
