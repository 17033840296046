import { ContactAddressDto, ContactAddressEntity, CreateContactAddress } from './ContactAddressDto';
import { ContactAttributeDto, ContactAttributeEntity, CreateContactAttribute } from './ContactAttributeDto';
import { ContactGroupsDto, ContactGroupsEntity, CreateContactGroupsDto } from './ContactGroupsDto';
import { ContactType } from '../../tentant-platform';

export interface CreateContactDto {
    prefix: string;
    firstName: string;
    lastName: string;
    middleName: string;
    preferredName: string;
    suffix: string;
    jobTitle: string;
    companyName: string;
    birthday: number; /// Date;
    gender: string;
    notes: string;
    revision: number; /// Date;
    registrationStatus: string;
    contactColor: string;
    removed: number; /// Date;
    contactType: ContactType;
    addresses: CreateContactAddress[];
    attributes: CreateContactAttribute[];
    groups: CreateContactGroupsDto[];
    publicId: number;
}

export interface ContactEntity {
    contactId: number;
    prefix: string;
    firstName: string;
    lastName: string;
    middleName: string;
    preferredName: string;
    suffix: string;
    jobTitle: string;
    companyName: string;
    birthday: number; /// Date;
    gender: string;
    notes: string;
    revision: number; /// Date;
    registrationStatus: string;
    contactColor: string;
    removed: number; /// Date;
    contactType: ContactType;
    addresses: ContactAddressEntity[];
    attributes: ContactAttributeEntity[];
    groups: ContactGroupsEntity[];
    publicId: number;
}

export class ContactDto {
    public contactId?: number | null;
    public prefix?: string | null;
    public firstName?: string | null;
    public lastName?: string | null;
    public middleName?: string | null;
    public preferredName?: string | null;
    public suffix?: string | null;
    public jobTitle?: string | null;
    public companyName?: string | null;
    public birthday?: number | null; /// Date;
    public gender?: string | null;
    public notes?: string | null;
    public revision?: number | null; /// Date;
    public registrationStatus?: string | null;
    public contactColor?: string | null;
    public removed?: number | null; /// Date;
    public contactType?: ContactType;
    public addresses?: ContactAddressDto[];
    public attributes?: ContactAttributeDto[];
    public groups?: ContactGroupsDto[];
    public publicId?: number | null;

    public static toCreate(model: {
        prefix: string;
        firstName: string;
        lastName: string;
        middleName: string;
        preferredName: string;
        suffix: string;
        jobTitle: string;
        companyName: string;
        birthday: number;
        gender: string;
        addresses: ContactAddressDto[];
        attributes: ContactAttributeDto[];
    }): ContactDto {
        const instance = new ContactDto();
        instance.prefix = model.prefix?.trim();
        instance.firstName = model.firstName?.trim();
        instance.lastName = model.lastName?.trim();
        instance.middleName = model.middleName?.trim();
        instance.preferredName = model.preferredName?.trim();
        instance.suffix = model.suffix?.trim();
        instance.jobTitle = model.jobTitle?.trim();
        instance.companyName = model.companyName?.trim();
        instance.birthday = model.birthday;
        instance.gender = model.gender;
        instance.addresses = model.addresses;
        instance.attributes = model.attributes;
        return instance;
    }
}
