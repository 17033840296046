import { Directive, ElementRef, inject, OnInit, Renderer2 } from '@angular/core';

import { CONTACT_COLORS } from '../constants';

@Directive({
    standalone: true,
    selector: '[pURandomColor]',
})
export class RandomColorDirective implements OnInit {
    public readonly colors = CONTACT_COLORS;

    private el = inject(ElementRef);
    private renderer = inject(Renderer2);

    public ngOnInit(): void {
        const randomColor = this.getRandomColor();
        this.renderer.setStyle(this.el.nativeElement, 'color', randomColor);
    }

    private getRandomColor(): string {
        const randomIndex = Math.floor(Math.random() * this.colors.length);
        return this.colors[randomIndex];
    }
}
