import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import { LoginHistoryDto } from '../dtos';

@Pipe({
    standalone: true,
    name: 'formatLoginDate',
})
export class FormatLoginDatePipe implements PipeTransform {
    constructor(private datePipe: DatePipe) {}

    public transform(loginHistoryDto: LoginHistoryDto[] | undefined): string {
        const firstLoginDate = loginHistoryDto?.[0]?.loginDate;
        if (!firstLoginDate) {
            return 'No login history available';
        }

        const date = new Date(firstLoginDate);
        return this.datePipe.transform(date, "MMM d, y 'at' h:mm a") ?? 'Invalid date';
    }
}
