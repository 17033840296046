import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';

import { BillingItemDto, BillingItemType, BillingItemWithEffectiveRate, Page, RateTierDto } from '../../../dtos';

export class ProductsServicesResource {
    private readonly _api = inject(HttpClient);

    public getBillingItems(config: { billingItemType?: BillingItemType; page?: number; size?: number }): Observable<Page<BillingItemDto>> {
        const params = config;
        return this._api.get<Page<BillingItemDto>>('accounting/v1/billing-items', { params: { ...params } });
    }

    public getBillingItem(itemId: number): Observable<BillingItemDto> {
        return this._api.get<BillingItemDto>(`accounting/v1/billing-items/${itemId}`);
    }

    public getBillingItemsWithEffectiveRates(): Observable<BillingItemWithEffectiveRate[]> {
        return this._api.get<BillingItemWithEffectiveRate[]>('accounting/v1/billing-items/effective-rates');
    }

    public saveRateTier(config: { billingItemId: number; rateTier: RateTierDto }): Observable<RateTierDto> {
        const { billingItemId, rateTier } = config;
        return this._api.post<RateTierDto>(`accounting/v1/billing-items/${billingItemId}/rate-tier`, rateTier);
    }

    public createServiceBillingItem(billingItem: BillingItemDto): Observable<BillingItemDto> {
        return this._api.post<BillingItemDto>('accounting/v1/billing-items/service', billingItem);
    }

    public createInventoryBillingItem(billingItem: BillingItemDto): Observable<BillingItemDto> {
        return this._api.post<BillingItemDto>('accounting/v1/billing-items/inventory', billingItem);
    }

    public deleteBillingItem(itemId: number): Observable<string> {
        return this._api.delete<string>(`accounting/v1/billing-items/${itemId}`);
    }

    public updateRateTier(config: { billingItemId: number; rateTierId: number; rateTier: RateTierDto }): Observable<RateTierDto> {
        const { billingItemId, rateTierId, rateTier } = config;
        return this._api.put<RateTierDto>(`accounting/v1/billing-items/${billingItemId}/rate-tier/${rateTierId}`, rateTier);
    }

    public updateServiceBillingItem(config: { billingItemId: number; billingItem: BillingItemDto }): Observable<BillingItemDto> {
        const { billingItem, billingItemId } = config;
        return this._api.put<BillingItemDto>(`accounting/v1/billing-items/service/${billingItemId}`, billingItem);
    }

    public updateInventoryBillingItem(config: { billingItemId: number; billingItem: BillingItemDto }): Observable<BillingItemDto> {
        const { billingItem, billingItemId } = config;
        return this._api.put<BillingItemDto>(`accounting/v1/billing-items/inventory/${billingItemId}`, billingItem);
    }
}
