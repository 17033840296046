export enum AnniversaryLabelEnum {
    anniversary = 'Anniversary',
    established = 'Established',
    other = 'Other',
}

export enum CustomLabelEnum {
    custom = 'Custom',
}

export enum EmailLabelEnum {
    personal = 'Personal',
    work = 'Work',
    main = 'Main',
    info = 'Info',
    sales = 'Sales',
    support = 'Support',
    billing = 'Billing',
    other = 'Other',
}

export enum PhoneLabelEnum {
    home = 'Home',
    homeFax = 'Home fax',
    work = 'Work',
    workFax = 'Work fax',
    main = 'Main',
    mobile = 'Mobile',
    other = 'Other',
}

export enum InstantMessageLabelEnum {
    whatsApp = 'WhatsApp',
    skype = 'Skype',
    yahooMessenger = 'Yahoo Messenger',
    iChat = 'iChat',
    icq = 'ICQ',
}

export enum SocialNetworkLabelEnum {
    twitter = 'Twitter',
    facebook = 'Facebook',
    linkedIn = 'LinkedIN',
    googlePlus = 'Google+',
}

export enum URLLabelEnum {
    companySite = 'Company site',
    blog = 'Blog',
    landingPage = 'Landing page',
    profile = 'Profile',
    other = 'Other',
}

export enum AddressLabelEnum {
    home = 'Home',
    work = 'Work',
    headOffice = 'Head office',
    office = 'Office',
    mailing = 'Mailing',
    poBox = 'PO Box',
    other = 'Other',
}

export interface InitialAccessListItem<T> {
    email: string;
    initialPermission: T;
    availablePermissions: Array<{
        name: string;
        value: T;
    }>;
}
