export namespace BlobUtils {
    export function base64ToBlob(base64String: string, contentType: string) {
        const byteCharacters = atob(base64String);

        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        const blob = new Blob([byteArray], { type: contentType });

        return blob;
    }
}
