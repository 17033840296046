import { LocalizationUtils } from './localization.utils';
import { NameValueType } from './name-value.interface';

export const COUNTRIES: Array<NameValueType<string, LocalizationUtils.CountryEnum>> = LocalizationUtils.getCountries();

export const USA_STATES: NameValueType[] = [
    { name: 'Alabama', value: 'AL' },
    { name: 'Alaska', value: 'AK' },
    { name: 'American Samoa', value: 'AS' },
    { name: 'Arizona', value: 'AZ' },
    { name: 'Arkansas', value: 'AR' },
    { name: 'California', value: 'CA' },
    { name: 'Colorado', value: 'CO' },
    { name: 'Connecticut', value: 'CT' },
    { name: 'Delaware', value: 'DE' },
    { name: 'District of Columbia', value: 'DC' },
    { name: 'Florida', value: 'FL' },
    { name: 'Georgia', value: 'GA' },
    { name: 'Guam', value: 'GU' },
    { name: 'Hawaii', value: 'HI' },
    { name: 'Idaho', value: 'ID' },
    { name: 'Illinois', value: 'IL' },
    { name: 'Indiana', value: 'IN' },
    { name: 'Iowa', value: 'IA' },
    { name: 'Kansas', value: 'KS' },
    { name: 'Kentucky', value: 'KY' },
    { name: 'Louisiana', value: 'LA' },
    { name: 'Maine', value: 'ME' },
    { name: 'Maryland', value: 'MD' },
    { name: 'Massachusetts', value: 'MA' },
    { name: 'Michigan', value: 'MI' },
    { name: 'Minnesota', value: 'MN' },
    { name: 'Mississippi', value: 'MS' },
    { name: 'Missouri', value: 'MO' },
    { name: 'Montana', value: 'MT' },
    { name: 'Nebraska', value: 'NE' },
    { name: 'Nevada', value: 'NV' },
    { name: 'New Hampshire', value: 'NH' },
    { name: 'New Jersey', value: 'NJ' },
    { name: 'New Mexico', value: 'NM' },
    { name: 'New York', value: 'NY' },
    { name: 'North Carolina', value: 'NC' },
    { name: 'North Dakota', value: 'ND' },
    { name: 'Northern Marianas Islands', value: 'MP' },
    { name: 'Ohio', value: 'OH' },
    { name: 'Oklahoma', value: 'OK' },
    { name: 'Oregon', value: 'OR' },
    { name: 'Pennsylvania', value: 'PA' },
    { name: 'Puerto Rico', value: 'PR' },
    { name: 'Rhode Island', value: 'RI' },
    { name: 'South Carolina', value: 'SC' },
    { name: 'South Dakota', value: 'SD' },
    { name: 'Tennessee', value: 'TN' },
    { name: 'Texas', value: 'TX' },
    { name: 'Utah', value: 'UT' },
    { name: 'Vermont', value: 'VT' },
    { name: 'Virginia', value: 'VA' },
    { name: 'Virgin Islands', value: 'VI' },
    { name: 'Washington', value: 'WA' },
    { name: 'West Virginia', value: 'WV' },
    { name: 'Wisconsin', value: 'WI' },
    { name: 'Wyoming', value: 'WY' },
];

export const CANADIAN_PROVINCES: NameValueType[] = [
    { name: 'Alberta', value: 'AB' },
    { name: 'British Columbia', value: 'BC' },
    { name: 'Manitoba', value: 'MB' },
    { name: 'New Brunswick', value: 'NB' },
    { name: 'Newfoundland & Labrador', value: 'NL' },
    { name: 'Northwest Territories', value: 'NT' },
    { name: 'Nova Scotia', value: 'NS' },
    { name: 'Nunavut', value: 'NU' },
    { name: 'Ontario', value: 'ON' },
    { name: 'Prince Edward Island', value: 'PE' },
    { name: 'Quebec', value: 'QC' },
    { name: 'Saskatchewan', value: 'SK' },
    { name: 'Yukon', value: 'YT' },
];

export const AUSTRALIA_STATES: NameValueType[] = [
    { name: 'Australian Capital Territory', value: 'ACT' },
    { name: 'New South Wales', value: 'NSW' },
    { name: 'Northern Territory', value: 'NT' },
    { name: 'Queensland', value: 'QLD' },
    { name: 'South Australia', value: 'SA' },
    { name: 'Tasmania', value: 'TAS' },
    { name: 'Victoria', value: 'VIC' },
    { name: 'Western Australia', value: 'WA' },
];

export const MICRONESIA_STATES: NameValueType[] = [
    { name: 'Chuuk', value: 'CH' },
    { name: 'Kosrae', value: 'KS' },
    { name: 'Pohnpei', value: 'PN' },
    { name: 'Yap', value: 'YP' },
];

export const INDIA_STATES: NameValueType[] = [
    { name: 'Andaman and Nicobar Islands', value: 'AN' },
    { name: 'Andhra Pradesh', value: 'AP' },
    { name: 'Arunachal Pradesh', value: 'AR' },
    { name: 'Assam', value: 'AS' },
    { name: 'Bihar', value: 'BR' },
    { name: 'Chandigarh', value: 'CH' },
    { name: 'Chhattisgarh', value: 'CG' },
    { name: 'Dadra and Nagar Haveli and Daman and Diu', value: 'DN' },
    { name: 'Delhi', value: 'DL' },
    { name: 'Goa', value: 'GA' },
    { name: 'Gujarat', value: 'GJ' },
    { name: 'Haryana', value: 'HR' },
    { name: 'Himachal Pradesh', value: 'HP' },
    { name: 'Jammu and Kashmir', value: 'JK' },
    { name: 'Jharkhand', value: 'JH' },
    { name: 'Karnataka', value: 'KA' },
    { name: 'Kerala', value: 'KL' },
    { name: 'Ladakh', value: 'LA' },
    { name: 'Lakshadweep', value: 'LD' },
    { name: 'Madhya Pradesh', value: 'MP' },
    { name: 'Maharashtra', value: 'MH' },
    { name: 'Manipur', value: 'MN' },
    { name: 'Meghalaya', value: 'ML' },
    { name: 'Mizoram', value: 'MZ' },
    { name: 'Nagaland', value: 'NL' },
    { name: 'Odisha', value: 'OD' },
    { name: 'Puducherry', value: 'PY' },
    { name: 'Punjab', value: 'PB' },
    { name: 'Rajasthan', value: 'RJ' },
    { name: 'Sikkim', value: 'SK' },
    { name: 'Tamil Nadu', value: 'TN' },
    { name: 'Telangana', value: 'TG' },
    { name: 'Tripura', value: 'TR' },
    { name: 'Uttar Pradesh', value: 'UP' },
    { name: 'Uttarakhand', value: 'UK' },
    { name: 'West Bengal', value: 'WB' },
];

export const PAKISTAN_PROVINCES: NameValueType[] = [
    { name: 'Azad Jammu and Kashmir', value: 'AJK' },
    { name: 'Balochistan', value: 'B' },
    { name: 'Gilgit-Baltistan', value: 'GB' },
    { name: 'Islamabad Capital Territory', value: 'ISB' },
    { name: 'Khyber Pakhtunkhwa', value: 'KP' },
    { name: 'Punjab', value: 'PB' },
    { name: 'Sindh', value: 'SD' },
];

export const COUNTRY_PARTS: Partial<Record<LocalizationUtils.CountryEnum, NameValueType[]>> = {
    [LocalizationUtils.CountryEnum['United States']]: USA_STATES,
    [LocalizationUtils.CountryEnum.Canada]: CANADIAN_PROVINCES,
    [LocalizationUtils.CountryEnum.Australia]: AUSTRALIA_STATES,
    [LocalizationUtils.CountryEnum.Micronesia]: MICRONESIA_STATES,
    [LocalizationUtils.CountryEnum.India]: INDIA_STATES,
    [LocalizationUtils.CountryEnum.Pakistan]: PAKISTAN_PROVINCES,
};

export const PROVINCE_COUNTRIES = new Set([
    LocalizationUtils.CountryEnum['United States'],
    LocalizationUtils.CountryEnum.Australia,
    LocalizationUtils.CountryEnum.Micronesia,
    LocalizationUtils.CountryEnum.India,
]);
export const STATE_COUNTRIES = new Set([LocalizationUtils.CountryEnum.Canada, LocalizationUtils.CountryEnum.Pakistan]);
