import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'genderFormat',
    standalone: true,
})
export class GenderFormatPipe implements PipeTransform {
    public transform(value: string | undefined): string {
        if (!value) return '';
        switch (value) {
            case 'M':
                return 'Male';
            case 'F':
                return 'Female';
            case 'O':
                return 'Other';
            case 'N':
                return 'Not applicable';
            case 'U':
                return 'Unknown';
            default:
                return 'Unknown';
        }
    }
}
