import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';

import { ContactGroupsDto, ContactGroupsTotalEntity } from '../../../dtos';

export class ContactGroupsResource {
    private readonly _api = inject(HttpClient);

    public createContactGroup(contactGroupsDto: ContactGroupsDto): Observable<ContactGroupsDto> {
        return this._api.post<ContactGroupsDto>(`contact/v1/groups`, contactGroupsDto);
    }

    public getContactGroup(groupId: number): Observable<ContactGroupsDto> {
        return this._api.get<ContactGroupsDto>(`contact/v1/groups/${groupId}`);
    }

    public updateContactGroup(groupId: number, contactGroupsDto: ContactGroupsDto): Observable<ContactGroupsDto> {
        return this._api.put<ContactGroupsDto>(`contact/v1/groups/${groupId}`, contactGroupsDto);
    }

    public deleteContactGroup(groupId: number): Observable<void> {
        return this._api.delete<void>(`contact/v1/groups/${groupId}`);
    }

    public getContactGroups(): Observable<ContactGroupsTotalEntity> {
        return this._api.get<ContactGroupsTotalEntity>(`contact/v1/groups`);
    }
}
