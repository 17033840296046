import { LocalizationUtils } from './localization.utils';

export namespace DatePipeUtils {
    const DateFormatToDatePipeMap: Map<LocalizationUtils.DateFormatEnum, string> = new Map<LocalizationUtils.DateFormatEnum, string>([
        [LocalizationUtils.DateFormatEnum['DD-MM-YYYY'], 'dd-MM-yyyy'],
        [LocalizationUtils.DateFormatEnum['DD/MM/YYYY'], 'dd/MM/yyyy'],
        [LocalizationUtils.DateFormatEnum['MM/DD/YYYY'], 'MM/dd/yyyy'],
        [LocalizationUtils.DateFormatEnum['YYYY-MM-DD'], 'yyyy-MM-dd'],
        [LocalizationUtils.DateFormatEnum['YYYY/MM/DD'], 'yyyy/MM/dd'],
    ]);
    export const DefaultDatePipeFormatKey = LocalizationUtils.DateFormatEnum['DD-MM-YYYY'];
    export const DefaultDatePipeFormat = DateFormatToDatePipeMap.get(DefaultDatePipeFormatKey)!;

    export function getDatePipeFormat(settingDateFormat: LocalizationUtils.DateFormatEnum | null): string {
        return settingDateFormat ? (DateFormatToDatePipeMap.get(settingDateFormat) ?? DefaultDatePipeFormat) : DefaultDatePipeFormat;
    }
}
