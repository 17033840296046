import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { JwtResponseDto } from '../../../dtos';
import { AuthenticationResource } from '../resources';

// TODO keep only AuthenticationResourceController
@Injectable()
export class RefreshTokenResourceController {
    private readonly _resource = inject(AuthenticationResource);

    public refreshToken(refreshToken: string): Observable<JwtResponseDto> {
        return this._resource.refreshToken({ refreshToken });
    }
}
