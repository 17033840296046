import {
    extensionsTreatedAsAudio,
    extensionsTreatedAsDocument,
    extensionsTreatedAsImage,
    extensionsTreatedAsPDF,
    extensionsTreatedAsSpreadsheet,
    extensionsTreatedAsVideo,
} from './file-type-constants';
import { FileType } from '../enums/file-type.enum';

export function getFileType(extension: string | undefined): FileType {
    const normalizedExtension = extension?.toLowerCase() ?? '';

    if (extensionsTreatedAsImage.includes(normalizedExtension)) {
        return FileType.Image;
    } else if (extensionsTreatedAsPDF.includes(normalizedExtension)) {
        return FileType.PDF;
    } else if (extensionsTreatedAsDocument.includes(normalizedExtension)) {
        return FileType.Document;
    } else if (extensionsTreatedAsSpreadsheet.includes(normalizedExtension)) {
        return FileType.Spreadsheet;
    } else if (extensionsTreatedAsVideo.includes(normalizedExtension)) {
        return FileType.Video;
    } else if (extensionsTreatedAsAudio.includes(normalizedExtension)) {
        return FileType.Audio;
    } else {
        return FileType.Unsupported;
    }
}
