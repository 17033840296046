import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';

import { Page, PaymentMethodDto } from '../../../dtos';

export class PaymentMethodsResource {
    private readonly _api = inject(HttpClient);

    public getPaymentMethods(params?: { page: number; size: number }): Observable<Page<PaymentMethodDto>> {
        return this._api.get<Page<PaymentMethodDto>>(`accounting/v1/payment-methods`, { params: { ...params } });
    }

    public getPaymentMethod(paymentMethodId: number): Observable<PaymentMethodDto> {
        return this._api.get<PaymentMethodDto>(`accounting/v1/payment-methods/${paymentMethodId}`);
    }

    public createPaymentMethod(paymentMethodDto: PaymentMethodDto): Observable<PaymentMethodDto> {
        return this._api.post<PaymentMethodDto>(`accounting/v1/payment-methods`, paymentMethodDto);
    }
    public updatePaymentMethod(paymentMethodId: number, paymentMethodDto: PaymentMethodDto): Observable<PaymentMethodDto> {
        return this._api.put<PaymentMethodDto>(`accounting/v1/payment-methods/${paymentMethodId}`, paymentMethodDto);
    }

    public togglePaymentMethodStatus(paymentMethodId: number): Observable<PaymentMethodDto> {
        return this._api.put<PaymentMethodDto>(`accounting/v1/payment-methods/${paymentMethodId}/active`, null);
    }

    public deletePaymentMethod(paymentMethodId: number): Observable<void> {
        return this._api.delete<void>(`accounting/v1/payment-methods/${paymentMethodId}`);
    }
}
