import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';

import { JwtResponseDto, LoginDto, RefreshTokenDto, UserDto } from '../../../dtos';

export class AuthenticationResource {
    private readonly _api = inject(HttpClient);

    public login(loginDto: LoginDto): Observable<JwtResponseDto> {
        return this._api.post<JwtResponseDto>(`user/v1/authn`, loginDto);
    }

    public refreshToken(refreshTokenDto: RefreshTokenDto): Observable<JwtResponseDto> {
        return this._api.post<JwtResponseDto>(`user/v1/authn/refresh-token`, refreshTokenDto);
    }

    public logout(): Observable<void> {
        return this._api.delete<void>(`user/v1/authn/logout`);
    }

    public getUserInfo(): Observable<UserDto> {
        return this._api.get<UserDto>(`user/v1/authn/user-info`);
    }
}
