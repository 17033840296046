import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    standalone: true,
    name: 'firstChar',
})
export class FirstCharPipe implements PipeTransform {
    public transform(value: string | undefined): string {
        return value?.charAt(0) ?? '';
    }
}
