import { Pipe, PipeTransform } from '@angular/core';

import { LedgerAccountDto } from '../dtos';

@Pipe({
    standalone: true,
    name: 'ledgerAccountStatus',
})
export class LedgerAccountStatusPipe implements PipeTransform {
    public transform(ledgerAccount: LedgerAccountDto): string {
        return ledgerAccount.active ? '' : 'Disabled';
    }
}
