import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';

import { LedgerAccountDto, LedgerAccountsDto } from '../../../dtos';

export class LedgerAccountResource {
    private readonly api = inject(HttpClient);

    public getLedgerAccount(id: number): Observable<LedgerAccountDto> {
        return this.api.get<LedgerAccountDto>(`accounting/v1/ledger-accounts/${id}`);
    }

    public getLedgerAccounts(): Observable<LedgerAccountsDto> {
        return this.api.get<LedgerAccountsDto>(`accounting/v1/ledger-accounts`);
    }

    public createLedgerAccount(ledgerAccountDto: LedgerAccountDto): Observable<LedgerAccountDto> {
        return this.api.post<LedgerAccountDto>(`accounting/v1/ledger-accounts`, ledgerAccountDto);
    }

    public updateLedgerAccount(ledgerAccountId: number, ledgerAccountDto: LedgerAccountDto): Observable<LedgerAccountDto> {
        return this.api.put<LedgerAccountDto>(`accounting/v1/ledger-accounts/${ledgerAccountId}`, ledgerAccountDto);
    }

    public canDeleteLedgerAccount(ledgerAccountId: number): Observable<void> {
        return this.api.get<void>(`accounting/v1/ledger-accounts/can-delete/${ledgerAccountId}`);
    }

    public deleteLedgerAccount(ledgerAccountId: number): Observable<void> {
        return this.api.delete<void>(`accounting/v1/ledger-accounts/${ledgerAccountId}`);
    }

    public toggleLedgerAccountStatus(ledgerAccountId: number): Observable<LedgerAccountDto> {
        return this.api.put<LedgerAccountDto>(`accounting/v1/ledger-accounts/${ledgerAccountId}/active`, null);
    }

    public getLedgerAccountIdByName(name: string): Observable<number> {
        return this.api.get<number>(`accounting/v1/ledger-accounts/name/${name}`);
    }
}
