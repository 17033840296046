import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';

import { TaxCodeDto } from '../../../dtos';

export class SalesTaxResource {
    private readonly _api = inject(HttpClient);

    public getTaxCodes(countryCode: string): Observable<TaxCodeDto[]> {
        return this._api.get<TaxCodeDto[]>(`accounting/v1/libraries/tax-codes/${countryCode}`);
    }
}
