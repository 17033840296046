import { Pipe, PipeTransform } from '@angular/core';

import { ContactAttributeDto } from '../dtos';

@Pipe({
    standalone: true,
    name: 'attributePhone',
})
export class AttributePhonePipe implements PipeTransform {
    public transform(attributes: ContactAttributeDto[]): ContactAttributeDto['value'] {
        const phoneAttribute = attributes.find((attr) => attr.attributeType === 'phone');
        return phoneAttribute?.value ? phoneAttribute.value : 'No phone';
    }
}
