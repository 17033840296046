import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';

import { CaseFileAccessDto, CaseFileDto, CaseFileNoteDto, Page } from '../../../dtos';

export class CaseFileResource {
    private readonly _api = inject(HttpClient);

    public createCaseFile(caseFileDto: CaseFileDto): Observable<CaseFileDto> {
        return this._api.post<CaseFileDto>(`casefile/v1/case-files`, caseFileDto);
    }

    public getCaseFiles(params: {
        locationId: number;
        page?: number;
        size?: number;
        searchText?: string;
        status?: string;
        type?: string;
    }): Observable<Page<CaseFileDto>> {
        return this._api.get<Page<CaseFileDto>>(`casefile/v1/case-files`, { params: { ...params } });
    }

    public getStarredCaseFiles(params: { locationId: number; page?: number; size?: number }): Observable<Page<CaseFileDto>> {
        return this._api.get<Page<CaseFileDto>>(`casefile/v1/case-files/starred`, { params: { ...params } });
    }

    public getCaseFile(
        caseId: number,
        params: {
            locationId: number;
        }
    ): Observable<CaseFileDto> {
        return this._api.get<CaseFileDto>(`casefile/v1/case-files/${caseId}`, { params: { ...params } });
    }

    public updateCaseFile(
        caseId: number,
        caseFileDto: CaseFileDto,
        params: {
            locationId: number;
        }
    ): Observable<CaseFileDto> {
        return this._api.put<CaseFileDto>(`casefile/v1/case-files/${caseId}`, caseFileDto, { params: { ...params } });
    }

    public deleteCaseFile(caseId: number, params: { locationId: number }): Observable<void> {
        return this._api.delete<void>(`casefile/v1/case-files/${caseId}`, { params: { ...params } });
    }

    public toggleCaseFileStatus(caseId: number, params: { locationId: number }): Observable<CaseFileDto> {
        return this._api.put<CaseFileDto>(`casefile/v1/case-files/toggle-status/${caseId}`, null, { params: { ...params } });
    }

    public setStarredCaseFile(caseId: number, params: { locationId: number }): Observable<CaseFileDto> {
        return this._api.put<CaseFileDto>(`casefile/v1/case-files/toggle-starred/${caseId}`, null, { params: { ...params } });
    }

    public moveHomeLocation(
        caseId: number,
        params: {
            locationId: number;
        }
    ): Observable<CaseFileDto> {
        return this._api.put<CaseFileDto>(`casefile/v1/case-files/home-location/${caseId}/${params.locationId}`, null, {
            params: { ...params },
        });
    }

    public deleteCaseFileNote(noteId: number, params: { locationId: number }): Observable<void> {
        return this._api.delete<void>(`casefile/v1/case-files/notes/${noteId}`, { params: { ...params } });
    }

    public updateCaseFileNote(
        noteId: number,
        caseFileNoteDto: CaseFileNoteDto,
        params: {
            locationId: number;
        }
    ): Observable<CaseFileNoteDto> {
        return this._api.put<CaseFileNoteDto>(`casefile/v1/case-files/notes/${noteId}`, caseFileNoteDto, { params: { ...params } });
    }

    public createCaseFileNote(
        caseId: number,
        caseFileNoteDto: CaseFileNoteDto,
        params: {
            locationId: number;
        }
    ): Observable<CaseFileDto> {
        return this._api.post<CaseFileDto>(`casefile/v1/case-files/notes/${caseId}`, caseFileNoteDto, { params: { ...params } });
    }

    public grantAccess(
        caseId: number,
        userId: number,
        params: {
            locationId: number;
        }
    ): Observable<CaseFileAccessDto> {
        return this._api.put<CaseFileAccessDto>(`casefile/v1/case-files/grant-access/${caseId}/${userId}`, null, { params: { ...params } });
    }

    public revokeAccess(
        caseId: number,
        userId: number,
        params: {
            locationId: number;
        }
    ): Observable<CaseFileDto> {
        return this._api.put<CaseFileDto>(`casefile/v1/case-files/revoke-access/${caseId}/${userId}`, null, { params: { ...params } });
    }

    public getAccessList(
        caseId: number,
        params: {
            locationId: number;
        }
    ): Observable<CaseFileDto> {
        return this._api.get<CaseFileDto>(`casefile/v1/case-files/access-list/${caseId}`, { params: { ...params } });
    }

    public getLocationProvidersWithoutCaseFileAccess(
        caseId: number,
        params: {
            locationId: number;
        }
    ): Observable<CaseFileDto> {
        return this._api.get<CaseFileDto>(`casefile/v1/case-files/access-list/providers/${caseId}`, { params: { ...params } });
    }

    public getAppointmentNotes(appointmentId: number, params: { locationId: number }): Observable<CaseFileNoteDto[]> {
        return this._api.get<CaseFileNoteDto[]>(`casefile/v1/case-files/appointments/${appointmentId}/notes`, { params: { ...params } });
    }
}
