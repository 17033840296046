import { CalendarType } from '../enums';
import { CalendarDisciplineDto, CalendarDisciplineEntity } from './CalendarDisciplineDto';

export interface CalendarEntity {
    calendarId: number;
    calendarName: string;
    description: string;
    onlineBookingDescription: string;
    teamMemberId: number;
    locationId: number;
    archived: boolean;
    calendarType: CalendarType;
    calscale: string;
    disciplines: CalendarDisciplineEntity[];
}

export class CalendarDto {
    public calendarId?: number;
    public calendarName?: string;
    public description?: string;
    public onlineBookingDescription?: string;
    public teamMemberId?: number;
    public locationId?: number;
    public archived?: boolean;
    public calendarType?: CalendarType;
    public calscale?: string;
    public disciplines?: CalendarDisciplineDto[];
}
