import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';

import { ContactDto, Direction, Page } from '../../../dtos';

export class ClientsResource {
    private readonly _api = inject(HttpClient);

    public getClients(
        groupId: number,
        params?: {
            searchText?: string;
            page?: number;
            size?: number;
            sort?: string;
            direction?: Direction;
        }
    ): Observable<Page<ContactDto>> {
        return this._api.get<Page<ContactDto>>(`contact/v1/contacts/by-group/${groupId}`, { params: { ...params } });
    }
}
