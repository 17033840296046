import { Pipe, PipeTransform } from '@angular/core';

import { UserRoles } from '../dtos';
import { OptionModel } from '../models';

@Pipe({
    standalone: true,
    name: 'roleLabel',
})
export class RoleLabelPipe implements PipeTransform {
    private readonly roles: Array<OptionModel<UserRoles>> = [
        {
            value: UserRoles.TENANT_ADMIN,
            label: 'Tenant Admin',
        },
        {
            value: UserRoles.MANAGER,
            label: 'Manager',
        },
        {
            value: UserRoles.OFFICE_ADMIN,
            label: 'Office Admin',
        },
        {
            value: UserRoles.PROVIDER,
            label: 'Provider',
        },
        {
            value: UserRoles.DIRECTOR,
            label: 'Director',
        },
    ];

    public transform(value: UserRoles[] | undefined): string {
        const roles = value ?? [];
        const role = roles[0];
        const foundRole = this.roles.find((r) => r.value === role);
        return foundRole ? foundRole.label : role;
    }
}
