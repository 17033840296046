/**
 * @see https://web.dev/patterns/files/save-a-file
 */
export async function saveFile(blob: Blob, suggestedName: string): Promise<void> {
    // API is supported
    const showSaveFilePicker: ((options: { suggestedName: string }) => Promise<FileSystemFileHandle>) | null =
        'showSaveFilePicker' in window
            ? (window.showSaveFilePicker as unknown as (options: { suggestedName: string }) => Promise<FileSystemFileHandle>)
            : null;
    // the app not run in an iframe.
    const supportsFileSystemAccess = (() => {
        try {
            return window.self === window.top;
        } catch {
            return false;
        }
    })();
    // If the File System Access API is supported…
    if (showSaveFilePicker && supportsFileSystemAccess) {
        try {
            // Show the file save dialog.
            const handle: FileSystemFileHandle = await showSaveFilePicker({ suggestedName });
            // Write the blob to the file.
            const writable = await handle.createWritable();
            await writable.write(blob);
            await writable.close();
            return;
        } catch (err: unknown) {
            // Fail silently if the user has simply canceled the dialog.
            if (typeof err === 'object' && err !== null && 'message' in err && 'name' in err && err.name !== 'AbortError') {
                console.error(err.name, err.message);
                return;
            }
        }
    }

    // Fallback if the File System Access API is not supported…
    // Create the blob URL.
    const blobURL = URL.createObjectURL(blob);
    // Create the `<a download>` element and append it invisibly.
    const a = document.createElement('a');
    a.href = blobURL;
    a.download = suggestedName;
    a.style.display = 'none';
    document.body.append(a);
    // Programmatically click the element.
    a.click();
    // Revoke the blob URL and remove the element.
    setTimeout(() => {
        URL.revokeObjectURL(blobURL);
        a.remove();
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    }, 1000);
}
