import { forwardRef, Provider, Type } from '@angular/core';
import { AbstractControl, NG_VALUE_ACCESSOR, ValidatorFn } from '@angular/forms';

export type FormControlKeyType<T> = keyof T extends string ? keyof T : never;

export namespace FormUtils {
    export function controlValueAccessorFactory(component: Type<{}>): Provider {
        return {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => component),
            multi: true,
        };
    }

    export namespace Validators {
        export function conditionalValidator(predicate: () => boolean, validator: ValidatorFn, errorNamespace?: string): ValidatorFn {
            return (formControl: AbstractControl) => {
                if (!formControl.parent) {
                    return null;
                }
                let error = null;
                if (predicate()) {
                    error = validator(formControl);
                }
                if (errorNamespace && error) {
                    const customError: Record<string, any> = {};
                    customError[errorNamespace] = error;
                    error = customError;
                }
                return error;
            };
        }

        export function removeErrors(control: AbstractControl, ...errorNames: string[]): void {
            if (control.errors !== null) {
                control.setErrors(
                    Object.keys(control.errors)
                        .filter((key: string) => !errorNames.includes(key))
                        .reduce((errors: {} | null, key: string) => ({ ...errors, [key]: control.errors![key] }), null)
                );
            }
        }
    }
}
