import { CaseTypeDto } from './CaseTypeDto';
import { ContactAddressDto, ContactAttributeDto, ContactDto } from '../../contact';

// NOTE: this interface will change after validation impovements
interface CreateConfig {
    prefix: string | null;
    firstName: string | null;
    lastName: string | null;
    middleName: string | null;
    preferredName: string | null;
    suffix: string | null;
    jobTitle: string | null;
    companyName: string | null;
    birthday: number | null;
    gender: string | null;
    addresses: ContactAddressDto[];
    attributes: ContactAttributeDto[];
    caseType: CaseTypeDto;
}

interface EditConfig {
    contactId: number;
    payerId: number | null;
    prefix: string | null;
    firstName: string | null;
    lastName: string | null;
    middleName: string | null;
    preferredName: string | null;
    suffix: string | null;
    jobTitle: string | null;
    companyName: string | null;
    birthday: number | null;
    gender: string | null;
    addresses: ContactAddressDto[];
    attributes: ContactAttributeDto[];
    caseType: CaseTypeDto;
}

export class PayerDto extends ContactDto {
    public payerId: undefined | number | null;
    public caseId: undefined | number | null;
    public override contactId: undefined | number;
    public caseType: undefined | CaseTypeDto | null;
    public defaultPayer: undefined | boolean | null;

    public static getCreateObject(config: Partial<CreateConfig>): PayerDto {
        const payer = new PayerDto();

        payer.prefix = config.prefix;
        payer.firstName = config.firstName;
        payer.lastName = config.lastName;
        payer.middleName = config.middleName;
        payer.preferredName = config.preferredName;
        payer.suffix = config.suffix;
        payer.jobTitle = config.jobTitle;
        payer.companyName = config.companyName;
        payer.birthday = config.birthday;
        payer.gender = config.gender;
        payer.addresses = config.addresses;
        payer.attributes = config.attributes;
        payer.caseType = config.caseType;

        return payer;
    }

    public static getEditObject(config: Partial<EditConfig>): PayerDto {
        const payer = new PayerDto();

        payer.contactId = config.contactId;
        payer.payerId = config.payerId;
        payer.prefix = config.prefix;
        payer.firstName = config.firstName;
        payer.lastName = config.lastName;
        payer.middleName = config.middleName;
        payer.preferredName = config.preferredName;
        payer.suffix = config.suffix;
        payer.jobTitle = config.jobTitle;
        payer.companyName = config.companyName;
        payer.birthday = config.birthday;
        payer.gender = config.gender;
        payer.addresses = config.addresses;
        payer.attributes = config.attributes;
        payer.caseType = config.caseType;

        return payer;
    }
}
