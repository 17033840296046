export namespace ValidationExpressions {
    export const URL_PATTERN: RegExp = /^(?:(ftp|http|https):\/\/)?(?:[\w-]+\.)+[a-z]{2,4}$/;
    /**
     * #RGB - 3 hexadecimal digits (short RGB)
     * #RRGGBB - 6 hexadecimal digits (long RGB)
     */
    export const HEX_COLOR_PATTERN: RegExp = /^#([A-Fa-f0-9]{3}|[A-Fa-f0-9]{6})$/;
    /**
     * #RGBA - 4 hexadecimal digits (short RGBA)
     * #RRGGBBAA - 8 hexadecimal digits (long RGBA)
     */
    export const HEX_COLOR_REQUIRED_ALPHA_PATTERN: RegExp = /^#([A-Fa-f0-9]{4}|[A-Fa-f0-9]{8})$/;
    /**
     * #RGB - 3 hexadecimal digits (short RGB)
     * #RRGGBB - 6 hexadecimal digits (long RGB)
     * #RGBA - 4 hexadecimal digits (short RGBA)
     * #RRGGBBAA - 8 hexadecimal digits (long RGBA)
     */
    export const HEX_COLOR_OPTIONAL_ALPHA_PATTERN: RegExp = /^#([A-Fa-f0-9]{3}|[A-Fa-f0-9]{6}|[A-Fa-f0-9]{4}|[A-Fa-f0-9]{8})$/;

    export const EXPIRE_DATE_MASK: RegExp = /(\d{2}\/\d{4}$)/g;
    export const PHONE_MASK: RegExp = /(\+?\d-\d{3}-\d{3}-\d{4}\sx\d{1,10}$)|(\d-\d{3}-\d{3}-\d{4}$)/g;
    export const EMAIL_MASK: RegExp =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    export const URL_PATTERN_RFC3986: RegExp = /^(([^:/?#]+):)?(\/\/([^/?#]*))?([^?#]*)(\?([^#]*))?(#(.*))?/;

    export const CANADIAN_POSTAL_CODE_PATTERN: RegExp = /^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z] [0-9][ABCEGHJ-NPRSTV-Z][0-9]$/;
    export const USA_ZIP_CODE_PATTERN: RegExp = /^[0-9]{5}(?:-[0-9]{4})?$/;

    export const STUB_PATTERN: RegExp = /^.*$/;

    export const DIGITS_PATTERN: RegExp = /^\d+$/;
}
