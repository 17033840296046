import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';

import { CaseTypeDto, Page } from '../../../dtos';

export class CaseTypeResource {
    private readonly _api = inject(HttpClient);

    public createCaseType(caseTypeDto: CaseTypeDto): Observable<CaseTypeDto> {
        return this._api.post<CaseTypeDto>(`casefile/v1/case-types`, caseTypeDto);
    }

    public getCaseTypes(params: { page?: number; size?: number }): Observable<Page<CaseTypeDto>> {
        return this._api.get<Page<CaseTypeDto>>(`casefile/v1/case-types`, { params: { ...params } });
    }

    public getCaseType(caseTypeId: number): Observable<CaseTypeDto> {
        return this._api.get<CaseTypeDto>(`casefile/v1/case-types/${caseTypeId}`);
    }

    public updateCaseType(caseTypeId: number, caseTypeDto: CaseTypeDto): Observable<CaseTypeDto> {
        return this._api.put<CaseTypeDto>(`casefile/v1/case-types/${caseTypeId}`, caseTypeDto);
    }

    public deleteCaseType(caseTypeId: number): Observable<void> {
        return this._api.delete<void>(`casefile/v1/case-types/${caseTypeId}`);
    }
}
