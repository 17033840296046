import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';

import { SettingsType } from '../../../dtos';

export class SettingsResource<T> {
    private readonly _api = inject(HttpClient);

    public getSettings(settingsName: SettingsType): Observable<T> {
        return this._api.get<T>(`location/v1/settings/${settingsName}`);
    }

    public updateSettings(settingsName: SettingsType, settingsDto: T): Observable<T> {
        return this._api.put<T>(`location/v1/settings/${settingsName}`, settingsDto);
    }
}
