export class RelationshipTypeDto {
    public relationshipTypeId?: number;
    public relationshipTypeName?: string;

    public static createRelationshipTypeRequest(name: string): RelationshipTypeDto {
        const instance = new RelationshipTypeDto();

        instance.relationshipTypeName = name;

        return instance;
    }

    public static updateRelationshipTypeRequest(name: string): RelationshipTypeDto {
        const instance = new RelationshipTypeDto();

        instance.relationshipTypeName = name;

        return instance;
    }
}
