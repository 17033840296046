export enum fieldTypeEnum {
    email = 'email',
    phone = 'phone',
    address = 'address',
    url = 'url',
    anniversary = 'anniversary',
    instantMessage = 'instantMessage',
    socialNetwork = 'socialNetwork',
    custom = 'custom',
}
