import { StorageDto } from '../../tentant-platform';

export enum TeamMemberFileViewMode {
    Tab = 'TAB',
    Viewer = 'VIEWER',
}

export interface TeamMemberFilesDto {
    fileId: undefined | number;
    storageDto: undefined | StorageDto;
}

export interface TeamMemberFileDescrDto {
    fileId: undefined | number;
    fileType: undefined | string;
    viewMode: TeamMemberFileViewMode;
}

export interface TeamMemberViewFileData {
    fileId: undefined | number;
    viewMode: TeamMemberFileViewMode;
}

export type TeamMemberFileDto = TeamMemberImageDto | TeamMemberTextDto;

export interface TeamMemberImageDto {
    image: undefined | string;
    imageHeight: undefined | number;
    imageWidth: undefined | number;
    contentType: undefined | string;
    fileName: undefined | string;
    filePath: undefined | string | null;
    fileSize: undefined | number;
    version: undefined | number | null;
    viewMode: TeamMemberFileViewMode;
}

export interface TeamMemberTextDto {
    blob: undefined | null;
    contentType: undefined | string;
    fileName: undefined | string;
    filePath: undefined | string | null;
    fileSize: undefined | number;
    version: undefined | number | null;
    viewMode: TeamMemberFileViewMode;
}
