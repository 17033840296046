export interface DisciplineEntity {
    disciplineId: number;
    disciplineName: string;
    professionalTitle: string;
    description: string;
    showInOnlineBooking: boolean;
}

export class DisciplineDto {
    public disciplineId: undefined | number;
    public disciplineName: undefined | string;
    public professionalTitle: undefined | string;
    public description: undefined | string;
    public showInOnlineBooking: undefined | boolean;
}
