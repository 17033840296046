import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';

import { Page, PayerDto } from '../../../dtos';

export class PayerResource {
    private readonly _api = inject(HttpClient);

    public createPayer(payerDto: PayerDto): Observable<PayerDto> {
        return this._api.post<PayerDto>(`casefile/v1/payers`, payerDto);
    }

    public getPayers(params: { searchText?: string; page?: number; size?: number }): Observable<Page<PayerDto>> {
        return this._api.get<Page<PayerDto>>(`casefile/v1/payers`, { params: { ...params } });
    }

    public getPayer(payerId: number): Observable<PayerDto> {
        return this._api.get<PayerDto>(`casefile/v1/payers/${payerId}`);
    }

    public updatePayer(payerId: number, payerDto: PayerDto): Observable<PayerDto> {
        return this._api.put<PayerDto>(`casefile/v1/payers/${payerId}`, payerDto);
    }

    public deletePayer(payerId: number): Observable<void> {
        return this._api.delete<void>(`casefile/v1/payers/${payerId}`);
    }

    public setDefaultPayer(payerId: number): Observable<PayerDto> {
        return this._api.put<PayerDto>(`casefile/v1/payers/default-payer/${payerId}`, null);
    }

    public setCaseType(payerId: number, caseTypeId: number): Observable<PayerDto> {
        return this._api.put<PayerDto>(`casefile/v1/payers/case-type/${payerId}/${caseTypeId}`, null);
    }
}
