import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';

import { CalendarAccessDto, CalendarDto, CalendarEntity, CalendarEventDto, CalendarPermission, Page, UserDto } from '../../../dtos';

export interface GetCalendarsParams {
    locationId?: number;
    archived?: boolean;
    page?: number;
    size?: number;
}

export class CalendarResource {
    readonly #http = inject(HttpClient);
    readonly #apiUrl = 'calendar/v1/calendars'; // Replace with your actual API base URL

    public createCalendar(calendarDto: CalendarDto): Observable<CalendarEntity> {
        const url = `${this.#apiUrl}`;
        return this.#http.post<CalendarEntity>(url, calendarDto);
    }

    public getCalendars(params?: GetCalendarsParams): Observable<Page<CalendarEntity>> {
        const url = `${this.#apiUrl}`;
        return this.#http.get<Page<CalendarEntity>>(url, { params: { ...params } });
    }

    public getCalendar(calendarId: number): Observable<CalendarEntity> {
        const url = `${this.#apiUrl}/${calendarId}`;
        return this.#http.get<CalendarEntity>(url);
    }

    public getTodayCalendarEvents(calendarId: number): Observable<CalendarEventDto[]> {
        const url = `${this.#apiUrl}/today/${calendarId}`;
        return this.#http.get<CalendarEventDto[]>(url);
    }

    public getCalendarEventsByDateRange(calendarId: number, from: string, to: string): Observable<CalendarEventDto[]> {
        const url = `${this.#apiUrl}/${calendarId}/${from}/${to}`;
        return this.#http.get<CalendarEventDto[]>(url);
    }

    public updateCalendar(calendarId: number, calendarDto: CalendarDto): Observable<CalendarEntity> {
        const url = `${this.#apiUrl}/${calendarId}`;
        return this.#http.put<CalendarEntity>(url, calendarDto);
    }

    public deleteCalendar(calendarId: number): Observable<void> {
        const url = `${this.#apiUrl}/${calendarId}`;
        return this.#http.delete<void>(url);
    }

    public toggleArchiveCalendar(calendarId: number): Observable<CalendarEntity> {
        const url = `${this.#apiUrl}/toggle-archive/${calendarId}`;
        return this.#http.put<CalendarEntity>(url, null);
    }

    public grantAccess(calendarId: number, userId: number, calendarPermission: CalendarPermission): Observable<CalendarAccessDto> {
        const url = `${this.#apiUrl}/grant-access/${calendarId}/${userId}`;
        return this.#http.put<CalendarAccessDto>(url, null, { params: { calendarPermission } });
    }

    public updateAccess(calendarId: number, userId: number, calendarPermission: CalendarPermission): Observable<CalendarAccessDto> {
        const url = `${this.#apiUrl}/update-access/${calendarId}/${userId}`;
        return this.#http.put<CalendarAccessDto>(url, null, { params: { calendarPermission } });
    }

    public revokeAccess(calendarId: number, userId: number): Observable<void> {
        const url = `${this.#apiUrl}/revoke-access/${calendarId}/${userId}`;
        return this.#http.put<void>(url, null);
    }

    public getAccessList(calendarId: number): Observable<CalendarAccessDto[]> {
        const url = `${this.#apiUrl}/access-list/${calendarId}`;
        return this.#http.get<CalendarAccessDto[]>(url);
    }

    public getUsersWithoutAccess(calendarId: number): Observable<UserDto[]> {
        const url = `${this.#apiUrl}/access-list/users/${calendarId}`;
        return this.#http.get<UserDto[]>(url);
    }
}
