import { Pipe, PipeTransform } from '@angular/core';

import { PayerDto } from '../dtos';

@Pipe({
    standalone: true,
    name: 'payerFirstChar',
})
export class PayerFirstCharPipe implements PipeTransform {
    public transform(payer: PayerDto): string {
        const firstChar = (value: string | undefined | null) => {
            const char = value?.charAt(0);
            return char === '' ? undefined : char;
        };

        return firstChar(payer.firstName) ?? firstChar(payer.lastName) ?? firstChar(payer.companyName) ?? '';
    }
}
