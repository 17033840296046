import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';

import { Page, RelationshipTypeDto } from '../../../dtos';

export class RelationshipTypeResource {
    private readonly _api = inject(HttpClient);

    public createRelationshipType(relationshipTypeDto: RelationshipTypeDto): Observable<RelationshipTypeDto> {
        return this._api.post<RelationshipTypeDto>(`casefile/v1/relationship-types`, relationshipTypeDto);
    }

    public getRelationshipTypes(params: { page?: number; size?: number }): Observable<Page<RelationshipTypeDto>> {
        return this._api.get<Page<RelationshipTypeDto>>(`casefile/v1/relationship-types`);
    }

    public getRelationshipType(relationshipTypeId: number): Observable<RelationshipTypeDto> {
        return this._api.get<RelationshipTypeDto>(`casefile/v1/relationship-types/${relationshipTypeId}`);
    }

    public updateRelationshipType(relationshipTypeId: number, relationshipTypeDto: RelationshipTypeDto): Observable<RelationshipTypeDto> {
        return this._api.put<RelationshipTypeDto>(`casefile/v1/relationship-types/${relationshipTypeId}`, relationshipTypeDto);
    }

    public deleteRelationshipType(relationshipTypeId: number): Observable<void> {
        return this._api.delete<void>(`casefile/v1/relationship-types/${relationshipTypeId}`);
    }
}
