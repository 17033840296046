import { NameValueType } from './name-value.interface';

/**
 * https://en.wikipedia.org/wiki/ISO_4217
 */
export namespace LocalizationUtils {
    export const enum Region {
        NorthAmerica = 'North America',
        Europe = 'Europe',
        Oceania = 'Oceania',
        Africa = 'Africa',
        Asia = 'Asia',
    }

    export enum CountryEnum {
        // North America:
        'United States' = 'United States',
        'Canada' = 'Canada',
        'Bahamas' = 'Bahamas',
        'Barbados' = 'Barbados',
        'Belize' = 'Belize',
        'Guyana' = 'Guyana',
        'Jamaica' = 'Jamaica',
        'Trinidad and Tobago' = 'Trinidad and Tobago',
        'Saint Kitts and Nevis' = 'Saint Kitts and Nevis',
        'Saint Lucia' = 'Saint Lucia',
        'Saint Vincent and the Grenadines' = 'Saint Vincent and the Grenadines',
        'Antigua and Barbuda' = 'Antigua and Barbuda',
        'Grenada' = 'Grenada',
        'Dominica' = 'Dominica',
        'Cayman Islands' = 'Cayman Islands',
        'British Virgin Islands' = 'British Virgin Islands',
        'Anguilla' = 'Anguilla',
        'Montserrat' = 'Montserrat',

        // Europe:
        'United Kingdom' = 'United Kingdom',
        'Ireland' = 'Ireland',
        'Malta' = 'Malta',
        'Gibraltar' = 'Gibraltar',
        'Isle of Man' = 'Isle of Man',
        'Guernsey' = 'Guernsey',
        'Jersey' = 'Jersey',
        'Akrotiri and Dhekelia' = 'Akrotiri and Dhekelia',
        'Cyprus' = 'Cyprus',

        // Oceania:
        'Australia' = 'Australia',
        'New Zealand' = 'New Zealand',
        'Fiji' = 'Fiji',
        'Papua New Guinea' = 'Papua New Guinea',
        'American Samoa' = 'American Samoa',
        'Samoa' = 'Samoa',
        'Solomon Islands' = 'Solomon Islands',
        'Vanuatu' = 'Vanuatu',
        'Kiribati' = 'Kiribati',
        'Tonga' = 'Tonga',
        'Tuvalu' = 'Tuvalu',
        'Nauru' = 'Nauru',
        'Marshall Islands' = 'Marshall Islands',
        'Micronesia' = 'Micronesia',
        'Palau' = 'Palau',
        'Cook Islands' = 'Cook Islands',
        'Niue' = 'Niue',
        'Northern Mariana Islands' = 'Northern Mariana Islands',
        'Guam' = 'Guam',
        'Pitcairn Islands' = 'Pitcairn Islands',

        // Africa:
        'Nigeria' = 'Nigeria',
        'Kenya' = 'Kenya',
        'South Africa' = 'South Africa',
        'Uganda' = 'Uganda',
        'Tanzania' = 'Tanzania',
        'Zimbabwe' = 'Zimbabwe',
        'Zambia' = 'Zambia',
        'Botswana' = 'Botswana',
        'Malawi' = 'Malawi',
        'Sierra Leone' = 'Sierra Leone',
        'Liberia' = 'Liberia',
        'Ghana' = 'Ghana',
        'Cameroon' = 'Cameroon',

        // Asia:
        'India' = 'India',
        'Pakistan' = 'Pakistan',
        'Philippines' = 'Philippines',
        'Singapore' = 'Singapore',
        'Malaysia' = 'Malaysia',
        'Brunei' = 'Brunei',
        'Sudan' = 'Sudan',
    }

    export function getCountries(): Array<NameValueType<string, CountryEnum>> {
        return Object.entries(CountryEnum).map(([key, value]) => ({
            name: key,
            value,
        }));
    }

    export const enum DateFormatEnum {
        'DD-MM-YYYY' = 'DD-MM-YYYY',
        'DD/MM/YYYY' = 'DD/MM/YYYY',
        'MM/DD/YYYY' = 'MM/DD/YYYY',
        'YYYY-MM-DD' = 'YYYY-MM-DD',
        'YYYY/MM/DD' = 'YYYY/MM/DD',
    }

    const year: number = new Date().getFullYear();
    const dateFormats: Array<NameValueType<string, DateFormatEnum>> = [
        {
            name: `12/31/${year}`,
            value: DateFormatEnum['MM/DD/YYYY'],
        },
        {
            name: `31/12/${year}`,
            value: DateFormatEnum['DD/MM/YYYY'],
        },
        {
            name: `${year}-12-31`,
            value: DateFormatEnum['YYYY-MM-DD'],
        },
        {
            name: `31-12-${year}`,
            value: DateFormatEnum['DD-MM-YYYY'],
        },
    ];
    export function getDateFormats(): Array<NameValueType<string, DateFormatEnum>> {
        return dateFormats;
    }

    export enum CurrencyEnum {
        'AUD' = 'AUD', // AUD	$
        'BBD' = 'BBD', // BBD	$
        'BND' = 'BND', // BND	$
        'BSD' = 'BSD', // BSD	$
        'BWP' = 'BWP', // BWP	P
        'BZD' = 'BZD', // BZD	$
        'CAD' = 'CAD', // CAD	$
        'EUR' = 'EUR', // EUR	€
        'FJD' = 'FJD', // FJD	$
        'GBP' = 'GBP', // GBP	£
        'GGP' = 'GGP', // GGP	£
        'GHS' = 'GHS', // GHS	₵
        'GIP' = 'GIP', // GIP	£
        'GYD' = 'GYD', // GYD	$
        'INR' = 'INR', // INR	₹
        'JMD' = 'JMD', // JMD	$
        'KES' = 'KES', // KES	KSh
        'KYD' = 'KYD', // KYD	$
        'LRD' = 'LRD', // LRD	$
        'MWK' = 'MWK', // MWK	K
        'MYR' = 'MYR', // MYR	RM
        'NGN' = 'NGN', // NGN	₦
        'NZD' = 'NZD', // NZD	$
        'PGK' = 'PGK', // PGK	K
        'PHP' = 'PHP', // PHP	₱
        'PKR' = 'PKR', // PKR	Rs
        'SBD' = 'SBD', // SBD	$
        'SDG' = 'SDG', // SDG	ج.س
        'SGD' = 'SGD', // SGD	$
        'SLL' = 'SLL', // SLL	Le
        'TOP' = 'TOP', // TOP	T$
        'TTD' = 'TTD', // TTD	$
        'TZS' = 'TZS', // TZS	TSh
        'UGX' = 'UGX', // UGX	USh
        'USD' = 'USD', // USD	$
        'VUV' = 'VUV', // VUV	Vt
        'WST' = 'WST', // WST	$
        'XAF' = 'XAF', // XAF	FCFA
        'XCD' = 'XCD', // XCD	$
        'ZAR' = 'ZAR', // ZAR	R
        'ZiG' = 'ZiG', // ZiG	ZIG
        'ZMW' = 'ZMW', // ZMW	ZK
    }

    export function getCurrencies(): Array<NameValueType<string, CurrencyEnum>> {
        return Object.entries(CurrencyEnum).map(([key, value]) => ({
            name: key,
            value,
        }));
    }

    const currencySymbols: Record<CurrencyEnum, string> = {
        [CurrencyEnum.AUD]: '$',
        [CurrencyEnum.BBD]: '$',
        [CurrencyEnum.BND]: '$',
        [CurrencyEnum.BSD]: '$',
        [CurrencyEnum.BWP]: 'P',
        [CurrencyEnum.BZD]: '$',
        [CurrencyEnum.CAD]: '$',
        [CurrencyEnum.EUR]: '€',
        [CurrencyEnum.FJD]: '$',
        [CurrencyEnum.GBP]: '£',
        [CurrencyEnum.GGP]: '£',
        [CurrencyEnum.GHS]: '₵',
        [CurrencyEnum.GIP]: '£',
        [CurrencyEnum.GYD]: '$',
        [CurrencyEnum.INR]: '₹',
        [CurrencyEnum.JMD]: '$',
        [CurrencyEnum.KES]: 'KSh',
        [CurrencyEnum.KYD]: '$',
        [CurrencyEnum.LRD]: '$',
        [CurrencyEnum.MWK]: 'K',
        [CurrencyEnum.MYR]: 'RM',
        [CurrencyEnum.NGN]: '₦',
        [CurrencyEnum.NZD]: '$',
        [CurrencyEnum.PGK]: 'K',
        [CurrencyEnum.PHP]: '₱',
        [CurrencyEnum.PKR]: 'Rs',
        [CurrencyEnum.SBD]: '$',
        [CurrencyEnum.SDG]: 'ج.س',
        [CurrencyEnum.SGD]: '$',
        [CurrencyEnum.SLL]: 'Le',
        [CurrencyEnum.TOP]: 'T$',
        [CurrencyEnum.TTD]: '$',
        [CurrencyEnum.TZS]: 'TSh',
        [CurrencyEnum.UGX]: 'USh',
        [CurrencyEnum.USD]: '$',
        [CurrencyEnum.VUV]: 'Vt',
        [CurrencyEnum.WST]: '$',
        [CurrencyEnum.XAF]: 'FCFA',
        [CurrencyEnum.XCD]: '$',
        [CurrencyEnum.ZAR]: 'R',
        [CurrencyEnum.ZiG]: 'ZIG',
        [CurrencyEnum.ZMW]: 'ZK',
    };

    export function getCurrencySymbol(currency: CurrencyEnum): string {
        return currencySymbols[currency] ?? '';
    }

    // 3 common patterns of currency displaying
    export const enum CurrencyFormatEnum {
        FIRST = '1,234.56',
        SECOND = '1.234,56',
        THIRD = '1 234,56',
    }

    // eslint-disable-next-line no-inner-declarations
    function getFormatters(currency: CurrencyEnum, pattern: string = '{s}{f}'): Array<NameValueType<string, CurrencyFormatEnum>> {
        return [
            {
                name: pattern.replace('{s}', currencySymbols[currency]).replace('{f}', CurrencyFormatEnum.FIRST),
                value: CurrencyFormatEnum.FIRST,
            },
            {
                name: pattern.replace('{s}', currencySymbols[currency]).replace('{f}', CurrencyFormatEnum.SECOND),
                value: CurrencyFormatEnum.SECOND,
            },
            {
                name: pattern.replace('{s}', currencySymbols[currency]).replace('{f}', CurrencyFormatEnum.THIRD),
                value: CurrencyFormatEnum.THIRD,
            },
        ];
    }

    const currencyFormats: Record<CurrencyEnum, Array<NameValueType<string, CurrencyFormatEnum>>> = {
        [CurrencyEnum.AUD]: getFormatters(CurrencyEnum.AUD),
        [CurrencyEnum.BBD]: getFormatters(CurrencyEnum.BBD),
        [CurrencyEnum.BND]: getFormatters(CurrencyEnum.BND),
        [CurrencyEnum.BSD]: getFormatters(CurrencyEnum.BSD),
        [CurrencyEnum.BWP]: [
            {
                name: 'P1,000.00',
                value: CurrencyFormatEnum.FIRST,
            },
            {
                name: 'P1.000,00',
                value: CurrencyFormatEnum.SECOND,
            },
            {
                name: 'P1 000,00',
                value: CurrencyFormatEnum.THIRD,
            },
        ],
        [CurrencyEnum.BZD]: getFormatters(CurrencyEnum.BZD),
        [CurrencyEnum.CAD]: getFormatters(CurrencyEnum.CAD),
        [CurrencyEnum.EUR]: getFormatters(CurrencyEnum.EUR),
        [CurrencyEnum.FJD]: getFormatters(CurrencyEnum.FJD),
        [CurrencyEnum.GBP]: getFormatters(CurrencyEnum.GBP),
        [CurrencyEnum.GGP]: getFormatters(CurrencyEnum.GGP),
        [CurrencyEnum.GHS]: getFormatters(CurrencyEnum.GHS),
        [CurrencyEnum.GIP]: getFormatters(CurrencyEnum.GIP),
        [CurrencyEnum.GYD]: getFormatters(CurrencyEnum.GYD),
        [CurrencyEnum.INR]: getFormatters(CurrencyEnum.INR),
        [CurrencyEnum.JMD]: getFormatters(CurrencyEnum.JMD),
        [CurrencyEnum.KES]: getFormatters(CurrencyEnum.KES),
        [CurrencyEnum.KYD]: getFormatters(CurrencyEnum.KYD),
        [CurrencyEnum.LRD]: getFormatters(CurrencyEnum.LRD),
        [CurrencyEnum.MWK]: getFormatters(CurrencyEnum.MWK),
        [CurrencyEnum.MYR]: getFormatters(CurrencyEnum.MYR),
        [CurrencyEnum.NGN]: getFormatters(CurrencyEnum.NGN),
        [CurrencyEnum.NZD]: getFormatters(CurrencyEnum.NZD),
        [CurrencyEnum.PGK]: getFormatters(CurrencyEnum.PGK),
        [CurrencyEnum.PHP]: getFormatters(CurrencyEnum.PHP),
        [CurrencyEnum.PKR]: getFormatters(CurrencyEnum.PKR),
        [CurrencyEnum.SBD]: getFormatters(CurrencyEnum.SBD),
        [CurrencyEnum.SDG]: getFormatters(CurrencyEnum.SDG, '{f}{s}'),
        [CurrencyEnum.SGD]: getFormatters(CurrencyEnum.SGD),
        [CurrencyEnum.SLL]: [
            {
                name: 'Le100.00',
                value: CurrencyFormatEnum.FIRST,
            },
        ],
        [CurrencyEnum.TOP]: getFormatters(CurrencyEnum.TOP),
        [CurrencyEnum.TTD]: getFormatters(CurrencyEnum.TTD),
        [CurrencyEnum.TZS]: getFormatters(CurrencyEnum.TZS, '{s} {f}'),
        [CurrencyEnum.UGX]: getFormatters(CurrencyEnum.UGX, '{s} {f}'),
        [CurrencyEnum.USD]: getFormatters(CurrencyEnum.USD),
        [CurrencyEnum.VUV]: [
            {
                name: '1,234VT',
                value: CurrencyFormatEnum.FIRST,
            },
        ],
        [CurrencyEnum.WST]: getFormatters(CurrencyEnum.WST),
        [CurrencyEnum.XAF]: [
            {
                name: '1 000,00 FCFA',
                value: CurrencyFormatEnum.FIRST,
            },
        ],
        [CurrencyEnum.XCD]: getFormatters(CurrencyEnum.XCD),
        [CurrencyEnum.ZAR]: getFormatters(CurrencyEnum.ZAR, '{s} {f}'),
        [CurrencyEnum.ZiG]: getFormatters(CurrencyEnum.ZiG, '{f} {s}'),
        [CurrencyEnum.ZMW]: getFormatters(CurrencyEnum.ZMW, '{f} {s}'),
    };

    export function getCurrencyFormats(): Record<CurrencyEnum, Array<NameValueType<string, CurrencyFormatEnum>>> {
        return currencyFormats;
    }

    export function getDefaultCurrencyFormats(): Array<NameValueType<string, CurrencyFormatEnum>> {
        return [
            {
                name: CurrencyFormatEnum.FIRST,
                value: CurrencyFormatEnum.FIRST,
            },
            {
                name: CurrencyFormatEnum.SECOND,
                value: CurrencyFormatEnum.SECOND,
            },
            {
                name: CurrencyFormatEnum.THIRD,
                value: CurrencyFormatEnum.THIRD,
            },
        ];
    }

    // export const enum NegativeFormattingEnum {
    //     '($99.99)' = '($99.99)',
    //     '(99.99 ZiG)' = '(99.99 ZiG)',
    //     '(K99.99)' = '(K99.99)',
    //     '(TSh 99.99)' = '(TSh 99.99)',
    //     '(USh 99.99)' = '(USh 99.99)',
    //     '(€99.99)' = '(€99.99)',
    //     '-$99.99' = '-$99.99',
    //     '-99,99 FCFA' = '-99,99 FCFA',
    //     '-99VT' = '-99VT',
    //     '-K99.99' = '-K99.99',
    //     '-KSh100' = '-KSh100',
    //     '-Le100.00' = '-Le100.00',
    //     '-P1,000.00' = '-P1,000.00',
    //     '-R 99.99' = '-R 99.99',
    //     '-RM99.99' = '-RM99.99',
    //     '-Rs99.99' = '-Rs99.99',
    //     '-T$99.99' = '-T$99.99',
    //     '-£99.99' = '-£99.99',
    //     '-₦99.99' = '-₦99.99',
    //     '-€99.99' = '-€99.99',
    //     '-₱99.99' = '-₱99.99',
    //     '-₵99.99' = '-₵99.99',
    //     '-₹99.99' = '-₹99.99',
    //     'ج.س99.99-' = 'ج.س99.99-',
    // }

    export enum NegativeFormattingEnum {
        MINUS = '-99.99',
        BRACKETS = '(99.99)',
    }

    export const enum TimeFormatEnum {
        '1:00 PM' = '1:00 PM',
        '13:00' = '13:00',
    }

    export function getTimeFormats(): Array<NameValueType<string, TimeFormatEnum>> {
        return [
            { name: '1:00 PM', value: TimeFormatEnum['1:00 PM'] },
            { name: '13:00', value: TimeFormatEnum['13:00'] },
        ];
    }

    export interface Settings {
        country: CountryEnum | null;
        currency: CurrencyEnum | null;
        currencyFormat: CurrencyFormatEnum | null;
        dateFormat: DateFormatEnum | null;
        negativeFormat: NegativeFormattingEnum | null;
        timeFormat: TimeFormatEnum | null;
    }
}
