import { Directive, ElementRef, Input, OnChanges, Renderer2 } from '@angular/core';

import { ContactAddressDto } from '../dtos';

export const googleMapLink: string = 'https://www.google.com/maps/search/?api=1&query=';

@Directive({
    standalone: true,
    selector: 'a[pUCommonAddressLink]',
})
export class AddressLinkDirective implements OnChanges {
    @Input('pUCommonAddressLink') public address!: ContactAddressDto;

    constructor(
        private elementRef: ElementRef<HTMLAnchorElement>,
        private renderer: Renderer2
    ) {}

    public ngOnChanges(): void {
        if (this.address) {
            const href: string = [this.address.postalCode, this.address.state, this.address.city, this.address.country, this.address.street]
                .filter((item): item is string => typeof item === 'string')
                .map((item) => item.trim())
                .filter(Boolean)
                .join(' ');

            if (href) {
                this.renderer.setAttribute(this.elementRef.nativeElement, 'href', googleMapLink + encodeURIComponent(href));
            } else {
                this.renderer.removeAttribute(this.elementRef.nativeElement, 'href');
            }
        } else {
            this.renderer.removeAttribute(this.elementRef.nativeElement, 'href');
        }
    }
}
