import { isPlatformBrowser } from '@angular/common';
import { ClassProvider, FactoryProvider, InjectionToken, PLATFORM_ID } from '@angular/core';

/**
 *  Create a new injection token for injecting the window into a component.
 */
export const WINDOW: InjectionToken<Window> = new InjectionToken<Window>('WindowToken');

/**
 *  Define abstract class for obtaining reference to the global window object.
 */
export abstract class WindowRef {
    get nativeWindow(): Window | object {
        throw new Error('Not implemented.');
    }
}

/**
 *  Define class that implements the abstract class and returns the native window object.
 */
export class BrowserWindowRef extends WindowRef {
    override get nativeWindow(): Window | object {
        return window;
    }
}

/**
 *  Create a factory function that returns the native window object.
 */
export function windowFactory(browserWindowRef: BrowserWindowRef, platformId: object): Window | object {
    if (isPlatformBrowser(platformId)) {
        return browserWindowRef.nativeWindow;
    }
    return {};
}

/**
 * Create an injectable provider for the WindowRef token that uses the BrowserWindowRef class.
 */
const BrowserWindowProvider: ClassProvider = {
    provide: WindowRef,
    useClass: BrowserWindowRef,
};

/**
 *  Create an injectable provider that uses the windowFactory function for returning the native window object.
 */
const WindowProvider: FactoryProvider = {
    provide: WINDOW,
    useFactory: windowFactory,
    deps: [WindowRef, PLATFORM_ID],
};

export function provideWindow(): Array<ClassProvider | FactoryProvider> {
    return [BrowserWindowProvider, WindowProvider];
}
