import { HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, mergeMap, Observable, ReplaySubject, switchMap, tap, throwError } from 'rxjs';

import { JwtUtils } from '@common/utils';

import { JwtResponseDto } from '../dtos';
import { RefreshTokenResourceController } from '../resources';
import { AUTH_MANAGER, AUTH_NAVIGATOR } from '../tokens';

let skipUntilRefreshSource: ReplaySubject<void> | null = null;

export const jwtAuthInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
    if (skipUntilRefreshSource) {
        return skipUntilRefreshSource.pipe(mergeMap(() => next(req)));
    }

    const authManager = inject(AUTH_MANAGER);

    if (!authManager.hasJwt()) {
        return next(req);
    }
    const jwt = authManager.getJwt()!;

    if (!JwtUtils.isTokenExpired(jwt.accessToken)) {
        return next(req);
    }

    const controller = inject(RefreshTokenResourceController);
    const authNavigator = inject(AUTH_NAVIGATOR);

    /**
     * TODO: Improve the approach for handling token refreshment to make it more efficient and maintainable.
     */
    skipUntilRefreshSource = new ReplaySubject<void>(1);
    return controller.refreshToken(jwt.refreshToken).pipe(
        switchMap((jwt: JwtResponseDto) => {
            authManager.setJwt(jwt);
            return next(req).pipe(
                tap(() => {
                    skipUntilRefreshSource!.next();
                    skipUntilRefreshSource = null;
                })
            );
        }),
        catchError((err: unknown) => {
            void authManager.resetJwt();
            void authNavigator.logoutNavigate();
            return throwError(() => err);
        })
    );
};
