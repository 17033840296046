import { Pipe, PipeTransform } from '@angular/core';

import { ContactAttributeDto } from '../dtos';

@Pipe({
    standalone: true,
    name: 'attributeEmail',
})
export class AttributeEmailPipe implements PipeTransform {
    public transform(attributes: ContactAttributeDto[]): ContactAttributeDto['value'] {
        const emailAttribute = attributes.find((attr) => attr.attributeType === 'email');
        return emailAttribute?.value ? emailAttribute.value : 'No email';
    }
}
