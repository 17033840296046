import { isPlatformBrowser } from '@angular/common';
import { ClassProvider, FactoryProvider, InjectionToken, PLATFORM_ID } from '@angular/core';

/**
 *  Create a new injection token for injecting the local storage into a component.
 */
export const PLATFORM_LOCAL_STORAGE: InjectionToken<Storage> = new InjectionToken<Storage>('Window Local Storage Token');

/**
 *  Define abstract class for obtaining reference to the global window object.
 */
export abstract class WindowLocalStorageRef {
    get nativeLocalStorage(): Storage | object {
        throw new Error('Not implemented.');
    }
}

/**
 *  Define class that implements the abstract class and returns the native local storage.
 */
export class BrowserWindowLocalStorageRef extends WindowLocalStorageRef {
    override get nativeLocalStorage(): Storage | object {
        return window.localStorage;
    }
}

/**
 *  Create a factory function that returns the native local storage.
 */
export function platformLocalStorageFactory(browserRef: BrowserWindowLocalStorageRef, platformId: object): Storage | object {
    if (isPlatformBrowser(platformId)) {
        return browserRef.nativeLocalStorage;
    }
    return {};
}

/**
 * Create an injectable provider for the WindowLocalStorageRef token that uses the BrowserLocalStorageProvider class.
 */
const BrowserLocalStorageProvider: ClassProvider = {
    provide: WindowLocalStorageRef,
    useClass: BrowserWindowLocalStorageRef,
};

/**
 *  Create an injectable provider that uses the platformLocalStorageFactory function for returning the native window object.
 */
const PlatformLocalStorageProvider: FactoryProvider = {
    provide: PLATFORM_LOCAL_STORAGE,
    useFactory: platformLocalStorageFactory,
    deps: [WindowLocalStorageRef, PLATFORM_ID],
};

export function providePlatformLocalStorage(): Array<ClassProvider | FactoryProvider> {
    return [BrowserLocalStorageProvider, PlatformLocalStorageProvider];
}
