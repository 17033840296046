import { DisplayNameFormatEnum } from '../enums';

export function formatDisplayName(
    firstName: string,
    lastName: string,
    format: DisplayNameFormatEnum = DisplayNameFormatEnum.firstLast
): string {
    if (format === DisplayNameFormatEnum.lastFirst) {
        return `${lastName}, ${firstName}`.trim();
    }
    return `${firstName} ${lastName}`.trim();
}
