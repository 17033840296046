import { Pipe, PipeTransform } from '@angular/core';

import { CaseFileDto } from '../dtos';

@Pipe({
    standalone: true,
    name: 'caseFileDisplay',
})
export class CaseFileDisplayPipe implements PipeTransform {
    public transform(caseFile: CaseFileDto): string {
        return `${caseFile.caseFileName} (${caseFile.caseId})`;
    }
}
