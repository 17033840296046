export enum ViewInfoType {
    PDF,
    Image,
    Document,
    Spreadsheet,
    Video,
    Audio,
    Unsupported,
}

export enum FileType {
    PDF,
    Image,
    Document,
    Spreadsheet,
    Video,
    Audio,
    Unsupported,
}
