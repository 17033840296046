import { LedgerAccountDto } from './ChartOfAccountsDto';

export enum BillingItemUnitMeasure {
    Each = 'Each',
    Minute = 'Minute',
    Procedure = 'Procedure',
    Session = 'Session',
    Page = 'Page',
    Pair = 'Pair',
}

export interface EditServiceConfig {
    name: string | undefined | null;
    description: string | undefined | null;
    sku: string | undefined | null;
    sell: boolean | undefined | null;
    buy: boolean | undefined | null;
    incomeAccountId: number | undefined | null;
    expenseAccountId: number | undefined | null;
}

export interface EditProductConfig {
    name: string | undefined | null;
    description: string | undefined | null;
    sku: string | undefined | null;
    upc: number | undefined | null;
    productBrand: string | undefined | null;
    modelNumber: string | undefined | null;
    sell: boolean | undefined | null;
    buy: boolean | undefined | null;
    trackInventory: boolean | undefined | null;
    assetAccountId: number | undefined | null;
    incomeAccountId: number | undefined | null;
    expenseAccountId: number | undefined | null;
}

export class BillingItemDto {
    public billingItemId: number | undefined | null;
    public name: string | undefined | null;
    public description: string | undefined | null;
    public type: BillingItemType | undefined | null;
    public productBrand: string | undefined | null;
    public modelNumber: string | undefined | null;
    public sku: string | undefined | null;
    public upc: number | undefined | null;
    public trackInventory: boolean | undefined | null;
    public sell: boolean | undefined | null;
    public buy: boolean | undefined | null;
    public incomeAccountId: number | undefined | null;
    public expenseAccountId: number | undefined | null;
    public assetAccountId: number | undefined | null;
    public rateTiers: RateTierDto[] | undefined | null;

    public static getEditServiceDto(config: EditServiceConfig): BillingItemDto {
        return {
            ...config,
            trackInventory: null,
            assetAccountId: undefined,
            upc: undefined,
            productBrand: undefined,
            modelNumber: undefined,
            billingItemId: undefined,
            rateTiers: undefined,
            type: BillingItemType.Service,
        };
    }

    public static getEditProductDto(config: EditProductConfig): BillingItemDto {
        return {
            ...config,
            billingItemId: undefined,
            rateTiers: undefined,
            type: BillingItemType.InventoryItem,
        };
    }
}

export class ExtendedBilingItemDto extends BillingItemDto {
    public incomeAccount?: LedgerAccountDto;
    public expenseAccount?: LedgerAccountDto;
    public assetAccount?: LedgerAccountDto;
}

export class BillingItemWithEffectiveRate {
    public billingItemId: number | undefined;
    public name: string | undefined;
    public rate: number | undefined;
}

export interface AddCashRateConfig {
    rateTierId: number | undefined | null;
    rate: number | null;
    quantity: number | null;
    unitMeasure: BillingItemUnitMeasure | null;
    salesTaxCode: string | null;
}

export interface EditCashRateConfig {
    rateTierId: number | undefined | null;
    newRate: number | null;
    quantity: number | null;
    effectiveDate: string | null;
    unitMeasure: BillingItemUnitMeasure | null;
    salesTaxCode: string | null;
}

export class RateTierDto {
    public rateTierId: number | undefined | null;
    public rateTierType: RateTierType | undefined | null;
    public rate: number | undefined | null;
    public newRate: number | undefined | null;
    public effectiveDate: string | undefined | null;
    public rateTierHistory: RateTierHistoryDto[] | undefined | null;
    public taxRate: number | undefined | null;
    public code: string | undefined | null;
    public attribute: string | undefined | null;
    public quantity: number | undefined | null;
    public unitMeasure: BillingItemUnitMeasure | undefined | null;
    public salesTaxCode: string | undefined | null;
    public provinceCode: string | undefined | null;

    public static getAddCashRate(config: AddCashRateConfig): RateTierDto {
        return {
            ...config,
            rateTierType: 'CASH',
            effectiveDate: undefined,
            taxRate: undefined,
            newRate: undefined,
            code: undefined,
            attribute: undefined,
            provinceCode: undefined,
            rateTierHistory: undefined,
        };
    }

    public static getEditCashRate(config: EditCashRateConfig): RateTierDto {
        return {
            ...config,
            rateTierType: 'CASH',
            taxRate: undefined,
            rate: undefined,
            code: undefined,
            attribute: undefined,
            provinceCode: undefined,
            rateTierHistory: undefined,
        };
    }
}

export class RateTierHistoryDto {
    public rateTierHistoryId: number | undefined;
    public rate: number | undefined;
    public taxRate: number | undefined;
    public effectiveDate: string | undefined;
    public created: string | undefined;
}

export enum BillingItemType {
    Service = 'SERVICE',
    InventoryItem = 'INVENTORY_ITEM',
}

export enum BillingItemFilter {
    Service = 'SERVICE',
    InventoryItem = 'INVENTORY_ITEM',
    All = 'ALL',
}

export type RateTierType = 'CASH' | 'MVA_ON_CAT' | 'MVA_ON_NON_CAT' | 'EHB' | 'WSIB' | 'OHIP';
