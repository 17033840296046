import { HttpClient, HttpParams } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';

import { LoginDto, Page, UserDto, UserEntity } from '../../../dtos';

export class UsersResource {
    readonly #api = inject(HttpClient);

    public getAllUsers(page: number, size: number): Observable<Page<UserEntity>> {
        const params = new HttpParams().set('page', page).set('size', size);
        return this.#api.get<Page<UserEntity>>(`user/v1/users`, { params });
    }

    public createUser(userDto: UserDto): Observable<UserEntity> {
        if (userDto.sendNotification) {
            const params = new HttpParams().set('sendNotification', userDto.sendNotification);
            return this.#api.post<UserEntity>(`user/v1/users`, userDto, { params });
        }
        return this.#api.post<UserEntity>(`user/v1/users`, userDto);
    }

    public getUser(userId: number, params?: { infoFull?: boolean }): Observable<UserEntity> {
        return this.#api.get<UserEntity>(`user/v1/users/${userId}`, { params: { ...params } });
    }

    public updateUser(userId: number, userDto: UserDto): Observable<UserEntity> {
        return this.#api.put<UserEntity>(`user/v1/users/${userId}`, userDto);
    }

    public deleteUser(userId: number): Observable<void> {
        return this.#api.delete<void>(`user/v1/users/${userId}`);
    }

    public toggleUserActive(userId: number): Observable<UserEntity> {
        return this.#api.put<UserEntity>(`user/v1/users/${userId}/active`, null);
    }

    public toggleLocation(userId: number, locationId: number): Observable<void> {
        return this.#api.put<void>(`user/v1/users/${userId}/location/${locationId}`, null);
    }

    public changeRole(userId: number, userDto: UserDto): Observable<UserEntity> {
        return this.#api.put<UserEntity>(`user/v1/users/${userId}/role`, userDto);
    }

    public resetPassword(userId: number, userDto: UserDto): Observable<UserEntity> {
        if (userDto.sendNotification) {
            const params = new HttpParams().set('sendNotification', userDto.sendNotification);
            return this.#api.put<UserEntity>(`user/v1/users/${userId}/reset-password`, userDto, { params });
        }
        return this.#api.put<UserEntity>(`user/v1/users/${userId}/reset-password`, userDto);
    }

    public recordAuditLinkedTeamMember(userId: number): Observable<void> {
        return this.#api.put<void>(`user/v1/users/audit-linked-team-member/${userId}`, null);
    }

    public changePassword(loginDto: LoginDto): Observable<UserEntity> {
        return this.#api.put<UserEntity>(`user/v1/users/change-password`, loginDto);
    }
}
