export interface ILoginDto {
    email: string;
    password: string;
}

export interface ILoginChangePassword {
    email: string;
    password: string;
    newPassword: string;
}

export class LoginDto {
    public email: undefined | string;
    public password: undefined | string;
    public newPassword: undefined | string;

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    private constructor() {}

    public static toLogin(model: ILoginDto): LoginDto {
        const instance = new LoginDto();
        instance.email = model.email;
        instance.password = model.password;
        return instance;
    }

    public static toChangePassword(model: ILoginChangePassword): LoginDto {
        const instance = new LoginDto();
        instance.email = model.email;
        instance.newPassword = model.newPassword;
        instance.password = model.password;
        return instance;
    }
}
