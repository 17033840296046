export namespace ExternalizeUtils {
    export function serialize<T>(obj: T): string {
        return utf8_to_b64(JSON.stringify(obj));
    }

    export function deserialize<T>(str: string): T | null {
        try {
            return JSON.parse(b64_to_utf8(str)) as T;
        } catch (e) {
            return null;
        }
    }
}

/**
 * @see {@link https://developer.mozilla.org/en-US/docs/Glossary/Base64#solution_1_%E2%80%93_escaping_the_string_before_encoding_it}
 */
function utf8_to_b64(str: string) {
    return btoa(unescape(encodeURIComponent(str)));
}

function b64_to_utf8(str: string) {
    return decodeURIComponent(escape(atob(str)));
}

export namespace _ExternalizeUtils {
    export function serialize<T>(params: T): string {
        return btoa(JSON.stringify(params));
    }

    export function deserialize<T>(filter: string): T {
        return JSON.parse(atob(filter));
    }
}
