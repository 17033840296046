import { inject, Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';

import { AuthNavigator } from '../interfaces';

@Injectable()
export class AdminAuthNavigatorService implements AuthNavigator {
    private readonly router = inject(Router);

    public async loginNavigate(): Promise<boolean> {
        return this.router.navigate(['admin']);
    }

    public loginUrlTree(): UrlTree {
        return this.router.createUrlTree(['admin']);
    }

    public async logoutNavigate(): Promise<boolean> {
        return this.router.navigate(['admin', 'login']);
    }

    public logoutUrlTree(): UrlTree {
        return this.router.createUrlTree(['admin', 'login']);
    }
}
