import { Injectable, WritableSignal, signal, computed } from '@angular/core';

import { LocalizationUtils, DatePipeUtils } from '../utils';

@Injectable()
export class LocalizationSource {
    readonly #dateFormatSource: WritableSignal<LocalizationUtils.DateFormatEnum | null> = signal(null);
    readonly #datePipeFormat = computed(() => DatePipeUtils.getDatePipeFormat(this.#dateFormatSource()));

    readonly #countrySource: WritableSignal<LocalizationUtils.CountryEnum | null> = signal(null);
    private readonly $currencySource: WritableSignal<LocalizationUtils.CurrencyEnum | null> = signal(null);
    private readonly $currency = this.$currencySource.asReadonly();

    public setCountry(country: LocalizationUtils.CountryEnum) {
        this.#countrySource.set(country);
    }

    public getCountry(): LocalizationUtils.CountryEnum | null {
        return this.#countrySource();
    }

    public setDateFormat(value: LocalizationUtils.DateFormatEnum) {
        this.#dateFormatSource.set(value);
    }

    public getDateFormat(): LocalizationUtils.DateFormatEnum | null {
        return this.#dateFormatSource();
    }

    public getDatePipeFormat(): string {
        return this.#datePipeFormat();
    }

    public getCurrencySymbol(): string {
        return LocalizationUtils.getCurrencySymbol(this.$currency() ?? LocalizationUtils.CurrencyEnum.CAD);
    }

    public getCurrencyCode(): LocalizationUtils.CurrencyEnum {
        return this.$currency() ?? LocalizationUtils.CurrencyEnum.CAD;
    }

    public setCurrency(currency: LocalizationUtils.CurrencyEnum) {
        this.$currencySource.set(currency);
    }
}
