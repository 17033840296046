import { Pipe, PipeTransform } from '@angular/core';

import { SupplierDto } from '../dtos';

@Pipe({
    standalone: true,
    name: 'supplierStatus',
})
export class SupplierStatusPipe implements PipeTransform {
    public transform(supplier: SupplierDto): string {
        return supplier.enabled ? 'Yes' : 'No';
    }
}
