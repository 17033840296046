import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';

import { TeamMemberProfessionDto } from '../../../dtos';

export class ProfessionsResource {
    private readonly _api = inject(HttpClient);

    public getProfessions(): Observable<TeamMemberProfessionDto[]> {
        return this._api.get<TeamMemberProfessionDto[]>(`accounting/v1/libraries/professions`);
    }
}
