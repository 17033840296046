import { InjectionToken } from '@angular/core';

export enum ApplicationFeatures {
    CALENDAR_APPLICATION = 'CALENDAR_APPLICATION',
    CASE_FILE_APPLICATION = 'CASE_FILE_APPLICATION',
    CONTACTS_APPLICATION = 'CONTACTS_APPLICATION',
    ACCOUNTING_APPLICATION = 'ACCOUNTING_APPLICATION',
    CHARTING_APPLICATION = 'CHARTING_APPLICATION',
    TEAM_APPLICATION = 'TEAM_APPLICATION',
    ADMIN_CONSOLE_APPLICATION = 'ADMIN_CONSOLE_APPLICATION',
    ACCOUNT_PROFILE_MODAL = 'ACCOUNT_PROFILE_MODAL',
    DEVELOPMENT_APPLICATION = 'DEVELOPMENT_APPLICATION',
    USER_APPLICATION = 'USER_APPLICATION',
    EXTERNALIZE_SECURITY_SUPPORT = 'EXTERNALIZE_SECURITY_SUPPORT',
}

export type ApplicationFeaturesAvailability = { [feature in ApplicationFeatures]: boolean };

export const ApplicationFeaturesAvailabilityToken = new InjectionToken<ApplicationFeaturesAvailability>(
    `Describes any application Feature that has different behaviour in development, stage or production`
);
