import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';

import { ContactDto, ContactEntity, ContactOrderByEnum, ContactType, Direction, Page } from '../../../dtos';

export interface GetContactsParams {
    searchText?: string;
    page?: number;
    size?: number;
    sort?: ContactOrderByEnum;
    direction?: Direction;
}

export class ContactResource {
    readonly #api = inject(HttpClient);

    public createContact(contactDto: ContactDto): Observable<ContactEntity> {
        return this.#api.post<ContactEntity>(`contact/v1/contacts`, contactDto);
    }

    public getContacts(params?: GetContactsParams): Observable<Page<ContactEntity>> {
        return this.#api.get<Page<ContactEntity>>(`contact/v1/contacts`, { params: { ...params } });
    }

    public getContactsByType(params?: {
        searchText?: string;
        contactType: ContactType;
        page: number;
        size: number;
    }): Observable<Page<ContactEntity>> {
        return this.#api.get<Page<ContactEntity>>(`contact/v1/contacts/contact-type`, { params: { ...params } });
    }

    public getContact(contactId: number): Observable<ContactEntity> {
        return this.#api.get<ContactEntity>(`contact/v1/contacts/${contactId}`);
    }

    public updateContact(contactId: number, contactDto: ContactDto): Observable<ContactEntity> {
        return this.#api.put<ContactEntity>(`contact/v1/contacts/${contactId}`, contactDto);
    }

    public deleteContact(contactId: number): Observable<void> {
        return this.#api.delete<void>(`contact/v1/contacts/${contactId}`);
    }

    public addContactGroup(contactId: number, groupId: number): Observable<ContactEntity> {
        return this.#api.put<ContactEntity>(`contact/v1/contacts/${contactId}/group/${groupId}`, null);
    }

    public getContactsByGroup(groupId: number, params?: GetContactsParams): Observable<Page<ContactEntity>> {
        return this.#api.get<Page<ContactEntity>>(`contact/v1/contacts/by-group/${groupId}`, { params: { ...params } });
    }

    public deleteContactGroup(contactId: number, groupId: number): Observable<ContactEntity> {
        return this.#api.delete<ContactEntity>(`contact/v1/contacts/${contactId}/group/${groupId}`);
    }
}
