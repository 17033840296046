import { inject, Injectable, NgZone } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';

import { LOCAL_STORAGE, LOCAL_STORAGE_OBSERVER } from '@common/utils';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
    readonly #localStorage = inject(LOCAL_STORAGE);
    readonly #localStorageObserver = inject(LOCAL_STORAGE_OBSERVER);

    private readonly ngZone = inject(NgZone);

    public receive<T>(key: string): Observable<T | null> {
        let storageEventListener: ((evt: StorageEvent) => void) | null;

        return new Observable<T | null>((subscriber: Subscriber<T | null>) => {
            this.ngZone.runOutsideAngular(() => {
                storageEventListener = (evt: StorageEvent) => {
                    if (evt.key === key) {
                        this.ngZone.run(() => {
                            subscriber.next(this.#localStorage.getItem(key));
                        });
                    }
                };

                this.#localStorageObserver.registerStorageEventListener(storageEventListener);
            });

            return () => {
                subscriber.complete();
                if (storageEventListener != null) {
                    this.#localStorageObserver.unregisterStorageEventListener(storageEventListener);
                }
            };
        });
    }
}
