import { inject, InjectionToken } from '@angular/core';

import { ExternalizeUtils } from './externalize.utils';
import { PLATFORM_LOCAL_STORAGE } from '../providers/provide-platform-local-storage';

export interface ILocalStorage {
    getItem<T>(key: string): T | null;
    setItem<T = any>(key: string, value: T): void;
    hasItem(key: string): boolean;
    removeItem(key: string): void;
}

export class LocalStorage implements ILocalStorage {
    readonly #localStorage = inject(PLATFORM_LOCAL_STORAGE);

    public getItem<T>(key: string): T | null {
        const item: string | null = localStorage.getItem(key);
        if (typeof item === 'string') {
            try {
                return JSON.parse(item) as T;
            } catch (e) {
                return null;
            }
        }
        return null;
    }

    public setItem<T = any>(key: string, value: T): void {
        let parsedValue: string;
        try {
            parsedValue = JSON.stringify(value);
        } catch (e) {
            parsedValue = 'null';
        }

        try {
            this.#localStorage.setItem(key, parsedValue);
        } catch (e) {
            // QuotaExceededError: DOMException
            this.#localStorage.setItem(key, 'null');
        }
    }

    public hasItem(key: string): boolean {
        return this.#localStorage.hasOwnProperty(key);
    }

    public removeItem(key: string): void {
        this.#localStorage.removeItem(key);
    }
}

export class ExternalizeLocalStorage implements ILocalStorage {
    readonly #localStorage = inject(PLATFORM_LOCAL_STORAGE);

    public getItem<T>(key: string): T | null {
        const item: string | null = localStorage.getItem(key);
        if (typeof item === 'string') {
            try {
                return ExternalizeUtils.deserialize(item) as T;
            } catch (e) {
                return null;
            }
        }
        return null;
    }

    public setItem<T = any>(key: string, value: T): void {
        let parsedValue: string;
        try {
            parsedValue = ExternalizeUtils.serialize(value);
        } catch (e) {
            parsedValue = 'null';
        }

        try {
            this.#localStorage.setItem(key, parsedValue);
        } catch (e) {
            // QuotaExceededError: DOMException
            this.#localStorage.setItem(key, 'null');
        }
    }

    public hasItem(key: string): boolean {
        return this.#localStorage.hasOwnProperty(key);
    }

    public removeItem(key: string): void {
        this.#localStorage.removeItem(key);
    }
}

export const LOCAL_STORAGE = new InjectionToken<ILocalStorage>('Local Storage Implementation Token');
