import { DATE_PIPE_DEFAULT_OPTIONS, DatePipeConfig } from '@angular/common';
import { inject, Injectable, Provider } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import {
    LuxonDateAdapter,
    MAT_LUXON_DATE_ADAPTER_OPTIONS,
    MatLuxonDateAdapterOptions,
    provideLuxonDateAdapter,
} from '@angular/material-luxon-adapter';
import { DateTime as LuxonDateTime } from 'luxon';

import { LocalizationSource } from './localization-source.service';

@Injectable()
class LocalizationDateAdapter extends LuxonDateAdapter {
    readonly #localizationSource = inject(LocalizationSource);
    readonly #options = inject<MatLuxonDateAdapterOptions>(MAT_LUXON_DATE_ADAPTER_OPTIONS, { optional: true });

    public override format(date: LuxonDateTime, displayFormat: string): string {
        if (!this.isValid(date)) {
            throw Error('LocalizationDateAdapter: Cannot format invalid date.');
        }

        const datePipeFormat = this.#localizationSource.getDatePipeFormat();

        if (this.#options?.useUtc) {
            return date.setLocale(this.locale).setZone('utc').toFormat(datePipeFormat);
        } else {
            return date.setLocale(this.locale).toFormat(datePipeFormat);
        }
    }
}

export function provideLocalizationDateAdapter(): Provider {
    return [
        provideLuxonDateAdapter(),
        {
            provide: DateAdapter,
            useClass: LocalizationDateAdapter,
        },
    ];
}

export function provideLocalizations(): Provider {
    return [
        LocalizationSource,
        {
            provide: DATE_PIPE_DEFAULT_OPTIONS,
            deps: [LocalizationSource],
            useFactory: (dateOptions: LocalizationSource): DatePipeConfig =>
                Object.create(
                    {},
                    {
                        dateFormat: {
                            get() {
                                return dateOptions.getDatePipeFormat();
                            },
                        },
                    }
                ),
        },
        provideLocalizationDateAdapter(),
    ];
}
