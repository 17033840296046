import { inject, InjectionToken } from '@angular/core';

import { WINDOW } from '../providers';

export interface ILocalStorageObserver {
    registerStorageEventListener(storageEventListener: (evt: StorageEvent) => void): void;
    unregisterStorageEventListener(storageEventListener: (evt: StorageEvent) => void): void;
}

export class LocalStorageObserver implements ILocalStorageObserver {
    readonly #windowRef = inject(WINDOW);

    public registerStorageEventListener(storageEventListener: (evt: StorageEvent) => void): void {
        this.#windowRef.addEventListener('storage', storageEventListener, false);
    }

    public unregisterStorageEventListener(storageEventListener: (evt: StorageEvent) => void): void {
        this.#windowRef.removeEventListener('storage', storageEventListener);
    }
}

export const LOCAL_STORAGE_OBSERVER = new InjectionToken<ILocalStorageObserver>('Local Storage Observer Implementation Token');
