export namespace ERROR_CODES {
    export const SERVER_ERROR: number = 400;
    /**
     * Unauthorized indicates that the client is not RFC2617 authenticated
     * and the server is initiating the authentication process.
     *
     */
    export const UNAUTHORIZED: number = 401;
    /**
     *  Forbidden indicates either that the client is RFC2617 authenticated
     *  and does not have authorization or that the server does not support RFC2617 for the requested resource
     *
     */
    export const FORBIDDEN: number = 403;
}
