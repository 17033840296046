import { PointerType } from '../enums/pointer-type';

export namespace ScreenUtils {
    export function isTouchEvent(event?: PointerEvent): boolean {
        return [PointerType.Touch, PointerType.Pen].includes(event?.pointerType as PointerType);
    }

    export function isTouchDevice(): boolean {
        return window?.matchMedia ? window.matchMedia('(pointer:coarse)').matches : false;
    }
}
