import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';

import { LocationDto, LocationEntity, Page } from '../../../dtos';

export class LocationResource {
    private readonly _api = inject(HttpClient);

    public createLocation(locationDto: LocationDto): Observable<LocationEntity> {
        return this._api.post<LocationEntity>(`location/v1/locations`, locationDto);
    }

    public getLocations(params?: { listAll?: boolean; page?: number; size?: number }): Observable<Page<LocationEntity>> {
        return this._api.get<Page<LocationEntity>>(`location/v1/locations`, { params: { ...params } });
    }

    public getLocation(locationId: number): Observable<LocationEntity> {
        return this._api.get<LocationEntity>(`location/v1/locations/${locationId}`);
    }

    public updateLocation(locationId: number, locationDto: LocationDto): Observable<LocationEntity> {
        return this._api.put<LocationEntity>(`location/v1/locations/${locationId}`, locationDto);
    }

    public deleteLocation(locationId: number): Observable<void> {
        return this._api.delete<void>(`location/v1/locations/${locationId}`);
    }

    public setHeadOffice(locationId: number): Observable<LocationEntity> {
        return this._api.put<LocationEntity>(`location/v1/locations/head-office/${locationId}`, null);
    }

    public toggleActiveStatus(locationId: number): Observable<LocationDto> {
        return this._api.put<LocationDto>(`location/v1/locations/${locationId}/active`, null);
    }
}
