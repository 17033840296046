export class ClosureReasonDto {
    public closureReasonId: undefined | number;
    public closureReasonName: undefined | string;

    public static createClosureReasonRequest(name: string): ClosureReasonDto {
        const instance = new ClosureReasonDto();

        instance.closureReasonName = name;

        return instance;
    }

    public static updateClosureReasonRequest(name: string): ClosureReasonDto {
        const instance = new ClosureReasonDto();

        instance.closureReasonName = name;

        return instance;
    }
}
