import { Pipe, PipeTransform } from '@angular/core';

import { ContactAttributeDto } from '../dtos';

@Pipe({
    standalone: true,
    name: 'phoneContactAttr',
})
export class PhonePipe implements PipeTransform {
    public transform(attributes: ContactAttributeDto[] = []): ContactAttributeDto['value'] {
        const phoneAttribute = attributes.find((attribute) => attribute.attributeType === 'phone' && attribute.attributeLabel === 'Main');
        return phoneAttribute ? phoneAttribute.value : 'No Phone';
    }
}
