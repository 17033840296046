export const KILOBYTE: number = 1024;
export const MEGABYTE: number = KILOBYTE * KILOBYTE;
export const MAX_SIZE_IN_MB: number = 25;
export const extensionsTreatedAsPDF: string[] = ['pdf'];
export const extensionsTreatedAsDocument: string[] = [
    'odt',
    'sxw',
    'rtf',
    'doc',
    'wpd',
    'txt',
    'wiki',
    'sxc',
    'tsv',
    'odp',
    'sxi',
    'ppt',
    'swf',
    'odg',
    'html',
    'docx',
    'fodt',
    'psw',
    'wps',
    'sdw',
];

export const extensionsTreatedAsSpreadsheet: string[] = ['xls', 'xlsx', 'xlsb', 'ods', 'csv'];

export const extensionsTreatedAsImage: string[] = [
    'jpg',
    'tiff',
    'bmp',
    'gif',
    'png',
    'tif',
    'jpeg',
    'wbmp',
    'raw',
    'tif',
    'jpeg',
    'sgi',
    'sid',
    'svg',
    'webp',
];

export const extensionsTreatedAsVideo: string[] = [
    'webm',
    'mkv',
    'vob',
    'mov',
    'qt',
    'avi',
    'wmv',
    'yuv',
    'rm',
    'asf',
    'amv',
    'mp4',
    'm4p',
    'm4v',
    'mpg',
    'mpeg',
    'mpe',
    'mpv',
    'm2v',
    'm2ts',
    '3gp',
];

export const extensionsTreatedAsAudio: string[] = [
    'mp3',
    'm4a',
    'aac',
    'amr',
    'ogg',
    'oga',
    'opus',
    'wma',
    'ra',
    'ram',
    'flac',
    'm4a',
    'wv',
    'tta',
    'aat',
    'shn',
    'wav',
    'aif',
    'lpcm',
    'mp2',
    'adts',
    'mib',
    'm4b',
    'eac3',
    'ac3',
    'dts',
    'mid',
    'midi',
    'sf2',
    'it',
    'xm',
    's3m',
    'mod',
];

export const MAX_SIZE_IN_BYTES: number = MAX_SIZE_IN_MB * MEGABYTE;
